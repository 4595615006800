import React from "react"
import "./aiFeedback.scss";

const AiFeedback = (props:any) => {
    const {aiFeedback} = props;
    // console.log(aiFeedback)
    const bulletPoints =aiFeedback && aiFeedback?.feedback
    .split(/\.\s+/) // Splits only when a dot is followed by a space
    .map((sentence:string) => sentence.trim())
    .filter((sentence:string) => sentence.length > 0);

    // console.log("aifeedback",bulletPoints);
  return (
    <div className='ai__feedback__wrapper'>
        <div className="ai__feedback__info">
            <div className="left">
                <p>Response Proficiency</p>
                <span>Based on clarity, content and delivery</span>
            </div>
            <div className="right">
                <div className="circle">
                {aiFeedback?.score}
                </div>
                <div className="text__info">
                    <p>Proficient</p>
                    <span>out of 10</span>
                </div>
            </div>
        </div>
        <ul className='ai__feedbacklist scrollable__feedback__list'>
            {
                bulletPoints?.map((item:any)=>(
                    <li key={item}>{item} </li>
                ))
            }
        </ul>
    </div>
  )
}

export default AiFeedback
