import React from "react";
import { ChevronRight, MessageCircle, MessageSquare } from "react-feather";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/store";
import { setShowProjectInitialScreen } from "../../../../../store/features/UI/UISlice";
import { fetchConversation } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import NewInputMessage from "../../InputMessage/NewInputMessage";
import "./index.scss";
import {
  setActiveConversationUniqueId,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";

const ProjectChatInitialScreen = (props: any) => {
  const { singleProjectData, fromChatBot } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleNavigateConversastionInsideProject = (e: any, conv: any) => {
    e.stopPropagation();
    dispatch(setShowProjectInitialScreen(false));
    if (!fromChatBot) {
      navigate(
        `/ai-conversation/${conv?.uniqueId}?projectId=${conv?.project_id}`
      );
      dispatch(
        fetchConversation({
          id: conv?.uniqueId,
          projectId: conv?.project_id,
        })
      );
    } else {
      // setLocalProjectIdForChatBot(conv?.project_id)
      dispatch(
        fetchConversation({
          id: conv?.uniqueId,
          projectId: conv?.project_id,
        })
      );
      dispatch(setActiveConversationUniqueId(conv?.uniqueId));
    }
  };
  return (
    <div
      className={`without__conv__wrapper ${
        fromChatBot && "fromChatbotProjectEmptyScreen"
      }`}
    >
      <div className="without__onv__container">
        <div className="name__description__section">
          {
            singleProjectData?.name && (<p className="p__name"><span>Project <ChevronRight/> </span>{singleProjectData?.name}</p>)
          }
          
        </div>

          <div className={`width__handle ${fromChatBot && "fromChatBotWidthHandle"}`}>

         
          <h2>What can I help with?</h2>
        <div className="withput__conv__input">
            <NewInputMessage fromChatBot={fromChatBot} />
        </div>

        {singleProjectData?.conversations?.length > 0 && (
          <div className="chat__history__wrapper">
            <h3 className="chat__history__title">Chat In this Project</h3>

            <div className="chat__history chat__custom__scrollbar">
              {singleProjectData?.conversations?.map((conv: any) => (
                <div className="chat__history__item">
                  <div className="history__icon">
                    <MessageSquare />
                  </div>
                  <div className="history__content">
                    <h3
                      aria-hidden
                      onClick={(e: any) =>
                        handleNavigateConversastionInsideProject(e, conv)
                      }
                    >
                      {/* {conv?.question} */}
                      {conv?.question.length > 35 ? `${conv.question.slice(0, 35)}...` : conv?.question}
                    </h3>
                    {/* <p>Corem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
         </div>
      </div>
    </div>
  );
};

export default ProjectChatInitialScreen;
