import React from "react";
import { X } from "react-feather";
import { formatFileSize } from "../../../../../helpers/utils/extras";
import "./index.scss";

const FilesPreview = (props: any) => {



  const {
    removeAttachment,
    index,
    fileIcon,
    fileName,
    fileType,
    fileSize,
    isRemove,
    isTAC
  } = props;
  return (
    <div className="preview-item">
      {isRemove && (
        <button type="button" onClick={() => removeAttachment(index)} className={`${isTAC && "isTacFileRemove"}`}>
          <X />
        </button>
      )}

      {fileIcon}

      <div className="name__size">
        <span>{fileName}</span>
        <p>
          {fileType} {formatFileSize(fileSize)}
        </p>
      </div>
    </div>
  );
};

export default FilesPreview;
