/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-extraneous-dependencies */
import React, { memo, useEffect, useId, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecordWebcam, CAMERA_STATUS } from "react-record-webcam";
import { useTimer } from "react-timer-hook";
import {
  ChevronLeft,
  ChevronRight,
  Play,
  Pause,
  RefreshCw,
  Trash2,
  ChevronUp,
  ChevronDown,
} from "react-feather";
import { useDebounce } from "use-debounce";
import { useAudioRecorder } from "react-audio-voice-recorder";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import vectorIcon from "../../../../assets/images/camera.svg";
import clockIcon from "../../../../assets/images/clockIcon.svg";
import lightClock from "../../../../assets/images/lightclock.svg";
import "./interviewpreparationcomp.scss";
import "./videoAndController.scss";
import QuestionDrawerComp from "../QuestionDrawer/QuestionDrawerComp";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  SubmitInterviewAnswer,
  // createVideoId,
  getInterviewRoles,
  // getPresignedUrlForAudioUpload,
  getPresignedUrlForAudioUploadToAws,
  getPresignedUrlForVideoUploadToAws,
  getQuestionAnswerById,
  uploadInterviewAnswerAudioToAws,
  uploadVideoToAws,
  // uploadVideoToBunny,
} from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";
import {
  closeAllAutoCall,
  setAllResposneSucessToFalse,
} from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import ProgressBar from "../../../../shared/ProgressBar/CircularProgressbar/ProgressBar";
import TimeOutModal from "./TimeOutModal";
import Feedback from "../Feedback/Feedback";
import Warning from "./Warning";
import ReRecordModal from "./ReRecordModal";
import RecordingLimitModal from "./RedordingLimitModal/RecordingLimitModal";
import VidelDeleteModal from "./VideoDeleteModal/VidelDeleteModal";
import UseNotificationQuestion from "./hooks/UseNotificationQuestion";
import RecordedVideo from "./RecordedVideo/RecordedVideo";
import TourInfoPopup from "../Tour/TourInfo/TourInfoPopup";
import {
  PopupInfoInQuestionSidebar,
  defaultProgress,
  goToNextStep,
  goToPrevStep,
  goTostepOne,
  setShowInnerPopup,
  setcurrentStep,
} from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import TourDonePopupModal from "../Tour/TourDonePopupModal/TourDonePopupModal";
import VideoProcessing from "./VideoProcessing";
import SelectAudioVideoModal from "../SelectAudioVideoModal/SelectAudioVideoModal";
import AudioEmptyScreen from "../AudioRecord/AudioRecordEmptyScreen/AudioEmptyScreen";
import AudioRecording from "../AudioRecord/AudioRecording/AudioRecording";
import PreviewRecordedAudio from "../AudioRecord/PreviewRecordedAudio/PreviewRecordedAudio";
import AudioPlayer from "../../../../shared/AudioPlayer/AudioPlayer";

const OPTIONS = {
  filename: "test-filename",
  mimeType: "video/mp4",
  width: 1280,
  height: 720,
  recordingLength: 15 * 60,
};

const InterviewPreparationComp = (props: any) => {
  const { selectedRoleData } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [debouncedValue] = useDebounce(currentIndex, 500);
  const [showSidebar, setShowSidebar] = useState(false);
  const [disabledSkip, setDisabledSkip] = useState(false);
  const [disabledBack, setDisabledBack] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUploadPorgress, setShowUploadPorgress] = useState(false);
  const [lastQuestionSubmitted, setLastQuestionSubmitted] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [duration, setDuration] = useState(0);
  const [selectedTabIndex, setSelectedTabIndex] = useState<any>();
  const [showReplyBox, setShowReplyBox] = useState(false);

  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [play, setPlay] = useState(false);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volume, setVolume] = useState(1);

  const [showReRecordModal, setShowReRecordModal] = useState(false);
  const [showRecordLimitModal, setShowRecordLimitModal] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [showWarning, setShowWarning] = useState(true);
  const [tsuInstance, setTsuInstance] = useState();
  const tempvideoId = useId();
  const [videoStatus, setVideoStatus] = useState({
    loading: false,
    success: false,
  });

  const [selectAudioVideo, setSelectAudioVideo] = useState(false);
  const [audioOrVideoResponseType, setAudioOrVideoResponseTye] =
    useState("video");
  const [
    showRecordbtnForSelectResponseType,
    setShowRecordbtnForSelectResponseType,
  ] = useState(true);
  const [showAudioPreview, setShowAudioPreview] = useState(false);

  const [audioDuration, setAudioDuration] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const dropdownRef = useRef<any>(null);

  const recorderControls = useAudioRecorder();

  //* ******FOR DELETE VIDEO MODAL ******** */
  const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);

  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  // For interview tour popup
  const { showInnerPopup, currentStep, steps, showDonePopupModal } =
    useAppSelector((store: RootStore) => store.ShowPopupSlice);
  const singleStepData = steps.filter((item: any) => item.id === currentStep);
  const isShowingDeleteVideoButton =
    interviewState.active_answer.answers?.length === undefined ||
    interviewState.active_answer.answers?.length === 1 ||
    interviewState.active_answer.answers?.length === 2 ||
    interviewState.active_answer.answers?.length === 3;

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  // Check if the user is came for the first time interview preparation
  const isFirstTime = authState?.tac_user?.onboarding?.interview?.is_completed;

  const handleNext = () => {
    dispatch(goToNextStep());
  };

  const handlePrev = () => {
    dispatch(goToPrevStep());
  };

  const goToInterviewPrepInstructionRoute = () => {
    navigate("/interview-preparation-instruction");
    dispatch(goTostepOne());
  };

  const location: any = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const time = new Date();
  time.setSeconds(
    time.getSeconds() +
      interviewState?.questions[debouncedValue]?.max_recording_time * 60
  );

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const durationCalculatorInSecond = () => {
    const minute =
      interviewState?.questions[debouncedValue]?.max_recording_time -
      minutes -
      1;
    const second = 60 - seconds;
    const totalSec = second + minute * 60;
    return totalSec;
  };
  //
  const triggerAllTimeOutFunction = () => {
    // show the timeout modal
    setShowTimeoutModal(true);

    if (audioOrVideoResponseType === "video") {
      // stop the camera recording
      recordWebcam.stop();
    } else {
      recorderControls.stopRecording();
      setShowAudioPreview(true);
    }

    // pause the countdown timer
    pause();

    // calculate the recorded duration
    const duration = durationCalculatorInSecond();
    setDuration(duration);

    // set the current step of interviewprep tour popup
    if (isFirstTime === 0) {
      dispatch(setcurrentStep(10));
      dispatch(defaultProgress(currentStep));
    }
  };

  // using the "react-timer-hook" package for count down timer
  const { seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp: time,
      autoStart: false,
      onExpire: () => triggerAllTimeOutFunction(),
    });

  // react-record-webcam used to record the answer through web cam.
  const recordWebcam = useRecordWebcam(OPTIONS);

  const resetTimer = (index: any) => {
    // reset the time to intital stage
    const time = new Date();
    time.setSeconds(
      time.getSeconds() +
        interviewState?.questions[debouncedValue + index]?.max_recording_time *
          60
    );
    restart(time, false);
  };

  const resetTimerForQuestionDrawer = (index: any) => {
    // reset the time to intital stage
    const time = new Date();
    time.setSeconds(
      time.getSeconds() +
        interviewState?.questions[index]?.max_recording_time * 60
    );
    restart(time, false);
  };

  const stopCamera = () => {
    if (
      recordWebcam.status === CAMERA_STATUS.RECORDING ||
      recordWebcam.status === CAMERA_STATUS.PREVIEW ||
      recordWebcam.status === CAMERA_STATUS.OPEN ||
      recordWebcam.status === CAMERA_STATUS.ERROR
    ) {
      recordWebcam.close();
    }
  };

  const goBackQuestion = (e: any) => {
    e.stopPropagation();
    setShowRecordbtnForSelectResponseType(true);
    setShowAudioPreview(false);
    setAudioOrVideoResponseTye("video");
    // decrease the question index and show the previous question
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }

    if (disableNext) {
      setDisableNext(false);
    }
    // clear the recorded video screen
    if (selectedTabIndex) {
      setSelectedTabIndex(null);
    }

    // close the add feedback
    if (showReplyBox) {
      setShowReplyBox(false);
    }

    if (
      interviewState.req_success.presigned_url ||
      interviewState.req_success.upload_video ||
      interviewState.req_success.add_answer
    ) {
      dispatch(setAllResposneSucessToFalse());
    }

    // restart the camera to capture new interview question
    if (audioOrVideoResponseType === "video") {
      stopCamera();
    }
    if (recorderControls.isRecording) {
      recorderControls.stopRecording();
      setAudioOrVideoResponseTye("video");
    }
    if (recorderControls.recordingBlob) {
      setAudioOrVideoResponseTye("video");
      recorderControls.recordingBlob = undefined;
    }

    resetTimer(-1);
  };

  const skipQuestion = (e: any) => {
    e.stopPropagation();

    // increase the questin index to show next question
    if (currentDuration < interviewState.questions?.length) {
      setCurrentIndex(currentIndex + 1);
    }

    // clear the recorded video screen
    if (selectedTabIndex) {
      setSelectedTabIndex(null);
    }

    // close the add feedback
    if (showReplyBox) {
      setShowReplyBox(false);
    }

    // restart the camera to capture new interview question
    if (audioOrVideoResponseType === "video") {
      stopCamera();
    }

    if (
      interviewState.req_success.presigned_url ||
      interviewState.req_success.upload_video ||
      interviewState.req_success.add_answer
    ) {
      dispatch(setAllResposneSucessToFalse());
    }
    setShowRecordbtnForSelectResponseType(true);
    setShowAudioPreview(false);
    setAudioOrVideoResponseTye("video");
    if (recorderControls.isRecording) {
      recorderControls.stopRecording();
    }
    if (recorderControls.recordingBlob) {
      recorderControls.recordingBlob = undefined;
    }

    // reset the countdown timer according to the new question time
    resetTimer(+1);
  };

  const submitHandler = async () => {
    if (audioOrVideoResponseType === "video") {
       // get presigned url from aws to upload video
    dispatch(getPresignedUrlForVideoUploadToAws());
    } else if (audioOrVideoResponseType === "audio") {
      // get presigned url from aws to Audio video
      dispatch(getPresignedUrlForAudioUploadToAws())
    }
  

    // upload in buttnynet 
    // const data = { title: interviewState.questions[currentIndex]?.question };
    // dispatch(createVideoId({ data }));

    // clear the recorded video screen
    setSelectedTabIndex(null);

    // close the add feedback
    setShowReplyBox(false);
    // enable the progress loading screen
    setShowUploadPorgress(true);
  };

  const startRecordingAndTimer = (e: any) => {
    e.stopPropagation();
    // clear the recorded video screen
    if (selectedTabIndex) {
      setSelectedTabIndex(null);
    }
    // show the video upload limit if user has already uploaded three video

    if (Object.keys(interviewState.active_answer).length > 0) {
      if (
        interviewState.active_answer?.answers &&
        interviewState.active_answer?.answers.length === 3 &&
        !showRecordLimitModal
      ) {
        setShowRecordLimitModal(true);
        return;
      }
    }

    if (audioOrVideoResponseType === "video") {
      if (recordWebcam.status === CAMERA_STATUS.OPEN) {
        recordWebcam.start();
      }
    } else if (audioOrVideoResponseType === "audio") {
      // recorderControls.recordingBlob = undefined;
      recorderControls.startRecording();
    }
    start();
    // set the current step of interviewprep tour popup
    dispatch(setcurrentStep(9));
    dispatch(defaultProgress(currentStep));
  };
  const stopRecordingAndTimer = (e: any) => {
    e.stopPropagation();
    if (audioOrVideoResponseType === "video") {
      // strop camera recording
      if (recordWebcam.status === CAMERA_STATUS.RECORDING) {
        recordWebcam.stop();
      }
    } else if (audioOrVideoResponseType === "audio") {
      recorderControls.stopRecording();
      if (recorderControls.isRecording) {
        recorderControls.stopRecording();
        setShowAudioPreview(true);
      }
    }
    // pause the countdown timer
    pause();

    // calculate the recorded duration
    const duration = durationCalculatorInSecond();
    setDuration(duration);

    // set the current step of interviewprep tour popup
    dispatch(setcurrentStep(10));
    dispatch(defaultProgress(currentStep));
  };

  const showRetakeModal = () => {
    if (Object.keys(interviewState.active_answer).length > 0) {
      if (
        interviewState.active_answer?.answers &&
        interviewState.active_answer?.answers.length === 3 &&
        !showRecordLimitModal
      ) {
        setShowRecordLimitModal(true);
        return;
      }
    }
    setShowReRecordModal(true);
  };
  const retakeVideo = (e: any) => {
    e.stopPropagation();
    setShowReRecordModal(false);
    // clear all the redux state that auto call the functions
    dispatch(setAllResposneSucessToFalse());

    resetTimer(0);
    if (audioOrVideoResponseType === "video") {
      recordWebcam.retake();
    } else {
      // if(recorderControls.recordingTime !==0){
      //   recorderControls.recordingTime=0;
      //   setAudioDuration(0);
      // }
      recorderControls.recordingBlob = undefined;
      setShowAudioPreview(false);
      recorderControls.startRecording();
      // start countdown timer
      start();
    }

    // set the current step of interviewprep tour popup
    dispatch(setcurrentStep(8));
    dispatch(defaultProgress(currentStep));
  };
  const getVideoFile = async () => {
   
    if (audioOrVideoResponseType === "video") {
      // get the recorded video
     const videoBlob = await recordWebcam.getRecording();
           // upload the video to aws through presigned url
          dispatch(
            uploadVideoToAws({
              url: interviewState.presigned_url,
              file: videoBlob,
              setUploadProgress: setUploadProgress,
            })
          );
    } else {
      // upload the Audio to aws through presigned url
     const audionBlob = recorderControls.recordingBlob;
      dispatch(
        uploadInterviewAnswerAudioToAws({
               url: interviewState.interview_answer_audio_presigned_url,
               file: audionBlob,
               setUploadProgress: setUploadProgress,
             })
           );
    }



    // dispatch(
    //   uploadVideoToBunny({
    //     data: blob,
    //     videoId: interviewState.presigned_url,
    //     setTsuInstance: setTsuInstance,
    //     videoStatus: videoStatus,
    //     setVideoStatus: setVideoStatus,
    //     setVideoUploadedProgress: setUploadProgress,
    //   })
    // );
  };

  useEffect(() => {
    if (interviewState.req_success.presigned_url || interviewState.req_success.interview_answer_audio_presigned_url) {
      getVideoFile();
    }
  }, [interviewState.req_success.presigned_url,interviewState.req_success.interview_answer_audio_presigned_url]);

  useEffect(() => {
    if (interviewState?.req_success?.upload_video || interviewState?.req_success?.interview_answer_upload_audio) {
      const url = interviewState.req_success.upload_video
        ? interviewState.presigned_url.split("?")
        : interviewState.interview_answer_audio_presigned_url.split("?");
  
      const data:any = {
        interview_question_id: interviewState.questions[currentIndex]?.id,
        interview_role_id: location.state.roleId,
        video_link: url[0], // Use only the first part of the URL
        duration:audioOrVideoResponseType === "video"? duration: audioDuration,
      };
  
      // Submitting the data to the database
      dispatch(SubmitInterviewAnswer(data)).then(() => {
        setShowRecordbtnForSelectResponseType(true);
        setShowAudioPreview(false);
        setAudioOrVideoResponseTye("video");
        recorderControls.recordingBlob = undefined;
      });
  
      // Clear all the Redux states that trigger auto-calls
      dispatch(closeAllAutoCall());
  
      setDisableNext(false);
      if (
        interviewState.questions[interviewState.questions.length - 1]?.id ===
        interviewState.questions[currentIndex]?.id
      ) {
        setLastQuestionSubmitted(true);
      }
    }
  }, [interviewState?.req_success?.upload_video, interviewState?.req_success?.interview_answer_upload_audio]);
  
  

  // called every time when the answer is submitted successfully
  useEffect(() => {
    if (interviewState.req_success.add_answer) {
      // hide the progress screen
      setShowUploadPorgress(false);

      // set the progress to initial stage 0
      setUploadProgress(0);

      if (audioOrVideoResponseType === "video") {
        // restart the camera
        recordWebcam.stop();
        stopCamera();
      }

      // clear all the redux state that auto call the functions
      resetTimer(0);
      dispatch(setAllResposneSucessToFalse());
      setVideoStatus({ loading: false, success: false });

      // Set the currentStep of interview tour popup in first upload only once but for now it set the current step every answer upload
      if (isShowingDeleteVideoButton) {
        dispatch(setcurrentStep(12));
        dispatch(defaultProgress(currentStep));
      }
    }
  }, [interviewState.req_success.add_answer]);

  // next question is displayed on the sreeen
  const nextHandler = (e: any) => {
    e.stopPropagation();
    // clear the recorded video screen
    if (selectedTabIndex) {
      setSelectedTabIndex(null);
    }

    // close the add feedback
    if (showReplyBox) {
      setShowReplyBox(false);
    }

    if (disabledSkip) {
      if (lastQuestionSubmitted) {
        recordWebcam.close();
        navigate("/interview-prepration-done", {
          state: { role: location.state.role },
        });
      }
      setDisableNext(true);
      return;
    }
    if (currentDuration < interviewState.questions?.length) {
      setCurrentIndex(currentIndex + 1);
    }

    if (audioOrVideoResponseType === "video") {
      stopCamera();
    }

    resetTimer(+1);

    if (
      interviewState.req_success.presigned_url ||
      interviewState.req_success.upload_video ||
      interviewState.req_success.add_answer
    ) {
      dispatch(setAllResposneSucessToFalse());
    }
    setShowRecordbtnForSelectResponseType(true);
    setShowAudioPreview(false);
    setAudioOrVideoResponseTye("video");
    if (recorderControls.recordingBlob) {
      recorderControls.recordingBlob = undefined;
    }
  };

  const openCamera = () => {
    // clear the recorded video screen
    if (selectedTabIndex) {
      setSelectedTabIndex(null);
    }

    if (Object.keys(interviewState.active_answer).length > 0) {
      if (
        interviewState.active_answer?.answers &&
        interviewState.active_answer?.answers.length === 3 &&
        !showRecordLimitModal
      ) {
        setShowRecordLimitModal(true);
        return;
      }
    }

    recordWebcam.open();

    // set the current step of interviewprep tour popup
    dispatch(setcurrentStep(8));
    dispatch(defaultProgress(currentStep));
  };

  const toggleAllQuestionSidebar = () => {
    setShowSidebar((showSidebar) => !showSidebar);

    if (audioOrVideoResponseType === "video") {
      if (
        recordWebcam.status === CAMERA_STATUS.PREVIEW &&
        showSidebar &&
        isFirstTime === 0
      ) {
        dispatch(setShowInnerPopup());
        dispatch(setcurrentStep(10));
      } else if (!showSidebar && isFirstTime === 0) {
        dispatch(setcurrentStep(6));
        dispatch(PopupInfoInQuestionSidebar());
        // dispatch(setShowInnerPopupFalse());
      } else if (showSidebar && isFirstTime === 0) {
        dispatch(setShowInnerPopup());
        dispatch(setcurrentStep(7));
      }
    }
  };

  const toggleActiveClass = showSidebar ? "active" : "";

  useEffect(() => {
    if (interviewState.questions?.length - 1 === currentIndex) {
      if (!disabledSkip) {
        setDisabledSkip(true);
      }
    } else if (disabledSkip) {
      setDisabledSkip(false);
    }

    if (currentIndex === 0) {
      if (!disabledBack) {
        setDisabledBack(true);
      }
    } else if (disabledBack) {
      setDisabledBack(false);
    }
  }, [currentIndex]);

  useEffect(() => {
    dispatch(
      getQuestionAnswerById({
        id: interviewState.questions[currentIndex]?.id,
      })
    );
    if (!showWarning) {
      setShowWarning(true);
    }
  }, []);

  useEffect(() => {
    if (
      (debouncedValue || debouncedValue === 0) &&
      interviewState.questions[currentIndex]?.id
    ) {
      dispatch(
        getQuestionAnswerById({
          id: interviewState.questions[currentIndex]?.id,
        })
      );
      if (!showWarning) {
        setShowWarning(true);
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (interviewState.req_success.delete_video) {
      setSelectedTabIndex(null);
      dispatch(
        getQuestionAnswerById({
          id: interviewState.questions[currentIndex]?.id,
        })
      );
      setShowWarning(true);
      setShowVideoDeleteModal(false);
    }
  }, [interviewState.req_success.delete_video]);

  useEffect(() => {
    if (recorderControls.recordingTime !== 0) {
      setAudioDuration(recorderControls.recordingTime);
    }
  }, [recorderControls.recordingTime]);

  //--------------
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainingSeconds = seconds - hours * 3600 - minutes * 60;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const playPauseVideo = () => {
    let video: any;
    if (selectedTabIndex) {
      video = document.getElementById("video");
    } else {
      video = document.getElementById("video3");
    }
    if (video.paused) {
      video.play();
      setPlay(true);
    } else {
      video.pause();
      setPlay(false);
    }
  };

  const handleSliderTime = (seconds: any) => {
    const video: any = document.getElementById("video3");
    video.currentTime = seconds;
    setCurrentDuration(seconds);
  };

  const handleVolumeChange = (volume: any) => {
    const video: any = document.getElementById("video3");

    if (video) {
      setVolume(volume);
      video.volume = volume;
    }
  };

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("rc-slider") &&
      !e.target.classList.contains("rc-slider-track") &&
      !e.target.classList.contains("rc-slider-rail") &&
      !e.target.classList.contains("rc-slider-step") &&
      !e.target.classList.contains("rc-slider-handle") &&
      !e.target.classList.contains("rc-slider-handle-dragging ") &&
      !e.target.classList.contains("volume__and__download__section") &&
      !e.target.classList.contains("volume__section") &&
      !e.target.classList.contains("volume___controller") &&
      !e.target.classList.contains("volume__slider__div") &&
      !e.target.classList.contains("checkbox-div")
    ) {
      setShowVolumeSlider(false);
    }
  });

  const notificationQuestion = UseNotificationQuestion(
    setCurrentIndex,
    recordWebcam,
    CAMERA_STATUS,
    resetTimerForQuestionDrawer,
    stopCamera
  );
  const handleClickOutSide = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    dispatch(getInterviewRoles());
    document.addEventListener("mousedown", handleClickOutSide);
    return () => {
      document.addEventListener("mousedown", handleClickOutSide);
    };
  }, []);
  const handleRoleSelection = (role: any) => {
    navigate("/interview-preparation", {
      state: { roleId: role.id, role: role.name },
    });
  };




  const isAudioFile = interviewState.active_answer?.answers?.length > 0 && interviewState?.active_answer?.answers[selectedTabIndex - 1]?.video_link ?.endsWith(".mp3");

  return (
    <div className="video__record__section__wrapper">
      {interviewState.req_success.questions &&
        interviewState.questions?.length > 0 && (
          <div className="record-video">
            <div className="content-section">
              <div className="heading-section">
                <div className="headings">
                  <h1>
                    {" "}
                    <ChevronLeft
                      className="back__icon"
                      onClick={goToInterviewPrepInstructionRoute}
                    />
                    Interview preparation
                  </h1>

                  {interviewState.loading.upload_video ||
                  recordWebcam.status === CAMERA_STATUS.RECORDING ? (
                    " "
                  ) : (
                    <span
                      className="header__show_all"
                      role="button"
                      aria-hidden
                      onClick={toggleAllQuestionSidebar}
                    >
                      Show all questions
                    </span>
                  )}
                </div>
                <div className="role-heading">
                  <div className="role-para">
                    <p>
                      Selected role:{" "}
                      <span>{interviewState?.active_answer?.role}</span>
                    </p>
                    {
                      interviewState.loading.upload_video ||
                      recordWebcam.status === CAMERA_STATUS.RECORDING || recorderControls.isRecording ? "": (
                        <div className="dropdown__div" ref={dropdownRef}>
                          <div
                            className={`dropdown-btn ${toggleActiveClass}`}
                            onClick={(e) => setIsActive(!isActive)}
                          >
                            <span>Change Role</span>
                            {isActive ? <ChevronUp /> : <ChevronDown />}
                          </div>
                          {isActive && (
                            <div className="dropdown-content">
                              {interviewState.req_success.role &&
                                interviewState.roles.map((role: any) => {
                                  return (
                                    <div
                                      key={role.id}
                                      className="dropdown-item"
                                      style={{
                                        display:
                                          location.state?.roleId === role?.id
                                            ? "none"
                                            : "",
                                      }}
                                      onClick={() => handleRoleSelection(role)}
                                    >
                                      {role.name}
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  <div className="record-time">
                    <div className="time">
                      <div className="sm__handle">
                        <img
                          src={
                            themeState.themeMode === "light"
                              ? lightClock
                              : clockIcon
                          }
                          alt=""
                        />
                        <span>Recording time:</span>
                      </div>
                      <p>
                        {minutes}:{seconds}
                        {recordWebcam.status === CAMERA_STATUS.RECORDING ||
                        recordWebcam.status === CAMERA_STATUS.PREVIEW ||
                        recorderControls.isRecording ||
                        showAudioPreview
                          ? ""
                          : "0"}
                      </p>
                    </div>
                    <div className="sm__show__all_question">
                      {interviewState.loading.upload_video ||
                      recordWebcam.status === CAMERA_STATUS.RECORDING ? (
                        " "
                      ) : (
                        <span
                          role="button"
                          aria-hidden
                          onClick={toggleAllQuestionSidebar}
                        >
                          Show all questions
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="line" />
              </div>

              <div className="question__and__warning__wrapper">
                {showWarning &&
                  interviewState.active_answer?.answers &&
                  interviewState.active_answer?.answers.length > 1 && (
                    <Warning setShowWarning={setShowWarning} />
                  )}
                <div className="question__section">
                  <span className="question ">
                    {interviewState.questions[currentIndex]?.question}
                  </span>
                </div>
                {showInnerPopup && (
                  <TourInfoPopup
                    title={singleStepData[0].title}
                    message={singleStepData[0].message}
                    handleOnClickBack={handlePrev}
                    handleOnClickNext={handleNext}
                    id={singleStepData[0].id}
                    selectedRoleId={selectedRoleData.id}
                    selectedRole={selectedRoleData.name}
                    showWarning={showWarning}
                    interviewState={interviewState}
                  />
                )}
              </div>
              <div className="video__and__feedback__wrapper">
                <div className="video__and__controller___wrapper">
                  {audioOrVideoResponseType === "video" ? (
                    <div className="video-section">
                      {!selectedTabIndex &&
                        recordWebcam.status !== CAMERA_STATUS.OPEN &&
                        recordWebcam.status !== CAMERA_STATUS.RECORDING &&
                        recordWebcam.status !== CAMERA_STATUS.PREVIEW && (
                          <div className="camera-video">
                            <img src={vectorIcon} alt="" />
                            <p>
                              After clicking record, the select response type
                              modal will be open.
                            </p>
                          </div>
                        )}
                      {/* {selectedTabIndex > 0 &&
                        interviewState?.active_answer?.answers?.length > 0 &&
                        interviewState?.active_answer?.answers[
                          selectedTabIndex - 1
                        ]?.status === 0 && <VideoProcessing />} */}
                      <video
                        className="video__controller"
                        ref={recordWebcam.webcamRef}
                        style={{
                          display: `${
                            (recordWebcam.status === CAMERA_STATUS.OPEN ||
                              recordWebcam.status ===
                                CAMERA_STATUS.RECORDING) &&
                            !selectedTabIndex
                              ? "block"
                              : "none"
                          }`,
                          transform: "scaleX(-1)",
                        }}
                        autoPlay
                        muted
                      />

                      {
                        isAudioFile ? (
                          <div className="answer__audio__player">
                            <AudioPlayer duration={interviewState?.active_answer?.answers[selectedTabIndex - 1]?.duration} url={ interviewState?.active_answer?.answers[selectedTabIndex - 1]?.video_link} AudiPlayerStyleHanlde/>
                          </div>
                        ): (
                          <>
                          <RecordedVideo
                        selectedTabIndex={selectedTabIndex}
                        setSelectedTabIndex={setSelectedTabIndex}
                        answer={interviewState.active_answer}
                        recordWebcam={recordWebcam}
                        CameraStatus={CAMERA_STATUS}
                        play={play}
                        setCurrentDuration={setCurrentDuration}
                        playPauseVideo={playPauseVideo}
                        formatTime={formatTime}
                        currentDuration={currentDuration}
                        handleSliderTime={handleSliderTime}
                        duration={duration}
                        setShowVolumeSlider={setShowVolumeSlider}
                        showVolumeSlider={showVolumeSlider}
                        handleVolumeChange={handleVolumeChange}
                        volume={volume}
                        setPlay={setPlay}
                      />

                      <Tooltip id="my-tooltip" />
                          </>
                        )
                      }

                    

                      {showUploadPorgress && (
                        <div className="video__loader__wrapper">
                          {" "}
                          <ProgressBar
                            uploadProgress={uploadProgress}
                            setUploadProgress={setUploadProgress}
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="audio__interview__answers">
                      {!recorderControls.isRecording &&
                        !showAudioPreview &&
                        !selectedTabIndex && <AudioEmptyScreen />}

                      {recorderControls.isRecording &&
                        !showAudioPreview &&
                        !selectedTabIndex && <AudioRecording />}

                      {recorderControls.recordingBlob &&
                        showAudioPreview &&
                        !selectedTabIndex && (
                          <PreviewRecordedAudio
                            blob={recorderControls.recordingBlob}
                            audioDuration={audioDuration}
                          />
                        )}
                      {showUploadPorgress && (
                        <div className="video__loader__wrapper">
                          {" "}
                          <ProgressBar
                            uploadProgress={uploadProgress}
                            setUploadProgress={setUploadProgress}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="controls">
                    <div className="left-controls">
                      <button
                        type="button"
                        className={`${
                          recordWebcam.status === CAMERA_STATUS.PREVIEW
                            ? " control disabled "
                            : "control "
                        }`}
                        onClick={playPauseVideo}
                        disabled={
                          recordWebcam.status !== CAMERA_STATUS.PREVIEW &&
                          !selectedTabIndex
                        }
                      >
                        {!play && (
                          <>
                            {" "}
                            <Play className="play__icon" />
                            <span>Play</span>
                          </>
                        )}
                        {play && (
                          <>
                            {" "}
                            <Pause className="play__icon" />
                            <span>Pause</span>
                          </>
                        )}
                      </button>
                      {audioOrVideoResponseType === "video" ? (
                        <button
                          type="button"
                          className={`${
                            recordWebcam.status === CAMERA_STATUS.PREVIEW
                              ? " control disabled"
                              : "control "
                          }`}
                          disabled={
                            recordWebcam.status !== CAMERA_STATUS.PREVIEW
                          }
                          onClick={() => showRetakeModal()}
                        >
                          <RefreshCw className="play__icon" />
                          <span>Re-record</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className={`${
                            recorderControls.recordingBlob && showAudioPreview
                              ? " control disabled"
                              : "control  "
                          }`}
                          disabled={
                            recorderControls.isRecording || !showAudioPreview
                          }
                          onClick={() => showRetakeModal()}
                        >
                          <RefreshCw className="play__icon" />
                          <span>Re-record</span>
                        </button>
                      )}

                      {/* <button
                        type="button"
                        className={`${
                          recordWebcam.status === CAMERA_STATUS.PREVIEW || !recorderControls.isRecording || showAudioPreview
                            ? " control disabled"
                            : "control "
                        }`}
                        disabled={recordWebcam.status !== CAMERA_STATUS.PREVIEW || !recorderControls.isRecording || !showAudioPreview }
                        onClick={() => showRetakeModal()}
                      >
                        <RefreshCw className="play__icon" />
                        <span>Re-record</span>
                      </button> */}
                    </div>

                    <div className="right-controls">
                      {!showRecordbtnForSelectResponseType &&
                        (audioOrVideoResponseType === "video" ? (
                          <div className="rec__btn__width__handle">
                            {recordWebcam.status === CAMERA_STATUS.CLOSED && (
                              <button
                                type="button"
                                className="btn"
                                onClick={() => openCamera()}
                              >
                                <div className="circle">
                                  <div className="red_circle" />
                                </div>{" "}
                                Record
                              </button>
                            )}

                            {recordWebcam.status === CAMERA_STATUS.OPEN && (
                              <button
                                type="button"
                                className="btn"
                                onClick={(e: any) => startRecordingAndTimer(e)}
                              >
                                <div className="circle">
                                  <div className="red_circle" />
                                </div>{" "}
                                Start Recording
                              </button>
                            )}

                            {recordWebcam.status ===
                              CAMERA_STATUS.RECORDING && (
                              <button
                                type="button"
                                className={
                                  recordWebcam.status !==
                                    CAMERA_STATUS.RECORDING &&
                                  recordWebcam.status === CAMERA_STATUS.PREVIEW
                                    ? "disable_btn"
                                    : "btn"
                                }
                                onClick={stopRecordingAndTimer}
                                disabled={
                                  recordWebcam.status !==
                                    CAMERA_STATUS.RECORDING &&
                                  recordWebcam.status === CAMERA_STATUS.PREVIEW
                                }
                              >
                                Done
                              </button>
                            )}

                            {recordWebcam.status === CAMERA_STATUS.PREVIEW && (
                              <button
                                type="button"
                                className="disable_btn"
                                onClick={stopRecordingAndTimer}
                                disabled={
                                  recordWebcam.status === CAMERA_STATUS.PREVIEW
                                }
                              >
                                Done
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="rec__btn__width__handle">
                            {!recorderControls.isRecording &&
                              !showAudioPreview && (
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={(e: any) =>
                                    startRecordingAndTimer(e)
                                  }
                                >
                                  Start
                                </button>
                              )}
                            {recorderControls.isRecording &&
                              !showAudioPreview && (
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={(e: any) => stopRecordingAndTimer(e)}
                                >
                                  done
                                </button>
                              )}
                          </div>
                        ))}
                      {showRecordbtnForSelectResponseType && (
                        <div className="rec__btn__width__handle">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => setSelectAudioVideo(true)}
                          >
                            <div className="circle">
                              <div className="red_circle" />
                            </div>{" "}
                            Record
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="prev-controls">
                    <div className="left-controls">
                      <div className="res__handle__btn">
                        <button
                          type="button"
                          className="back "
                          onClick={(e: any) => goBackQuestion(e)}
                          disabled={disabledBack}
                        >
                          <ChevronLeft />
                          <span role="button">Go back</span>
                        </button>

                        <button
                          type="button"
                          className="forwad"
                          onClick={(e: any) => skipQuestion(e)}
                          disabled={disabledSkip}
                        >
                          <span role="button">Skip this question</span>
                          <ChevronRight />
                        </button>
                      </div>
                      {interviewState.active_answer.answers?.length > 0 && (
                        <button
                          type="button"
                          className="video__delete__btn"
                          onClick={() => {
                            setShowVideoDeleteModal(true);
                          }}
                        >
                          <Trash2 />
                          <span role="button">Delete Responses</span>
                        </button>
                      )}
                    </div>
                    <div className="next-controls">
                      <div className="submit-btn">
                        {audioOrVideoResponseType === "video" ? (
                          <button
                            className={
                              recordWebcam.status !== CAMERA_STATUS.PREVIEW ||
                              interviewState.req_success.add_answer ||
                              interviewState.loading.add_answer ||
                              interviewState.loading.upload_video
                                ? "disable_btn "
                                : "btn"
                            }
                            disabled={
                              recordWebcam.status !== CAMERA_STATUS.PREVIEW ||
                              interviewState.req_success.add_answer ||
                              interviewState.loading.add_answer ||
                              interviewState.loading.upload_video
                            }
                            type="button"
                            onClick={submitHandler}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            className={
                              recorderControls.isPaused ||
                              recorderControls.isRecording ||
                              !showAudioPreview ||
                              audioOrVideoResponseType === "video" ||
                              interviewState.req_success.add_answer ||
                              interviewState.loading.add_answer ||
                              interviewState.loading.upload_video
                                ? "disable_btn "
                                : "btn"
                            }
                            disabled={
                              recorderControls.isPaused ||
                              recorderControls.isRecording ||
                              !showAudioPreview ||
                              audioOrVideoResponseType === "video" ||
                              interviewState.req_success.add_answer ||
                              interviewState.loading.add_answer ||
                              interviewState.loading.upload_video
                            }
                            type="button"
                            onClick={submitHandler}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                      <div className="next-btn">
                        <button
                          className={
                            ((recordWebcam.status === CAMERA_STATUS.RECORDING ||
                              recordWebcam.status === CAMERA_STATUS.PREVIEW ||
                              recorderControls.isRecording) &&
                              !interviewState.req_success.add_answer) ||
                            disableNext
                              ? "disable_btn"
                              : "btn"
                          }
                          disabled={
                            (recordWebcam.status === CAMERA_STATUS.RECORDING ||
                              recordWebcam.status === CAMERA_STATUS.PREVIEW ||
                              recorderControls.isRecording) &&
                            !interviewState.req_success.add_answer
                          }
                          type="button"
                          onClick={(e: any) => nextHandler(e)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <Feedback
                  feedback={interviewState.questions[currentIndex]}
                  setSelectedTabIndex={setSelectedTabIndex}
                  selectedTabIndex={selectedTabIndex}
                  showReplyBox={showReplyBox}
                  setShowReplyBox={setShowReplyBox}
                  stopCamera={stopCamera}
                  setCurrentDuration={setCurrentDuration}
                  currentDuration={currentDuration}
                  classNamehandle
                  setAudioOrVideoResponseTye={setAudioOrVideoResponseTye}

                  // ;                   feedback={interviewState.questions[currentIndex].feedback}
                />
              </div>
            </div>
            {showSidebar && (
              <QuestionDrawerComp
                setCurrentIndex={setCurrentIndex}
                toggleActiveClass={toggleActiveClass}
                toggleAllQuestionSidebar={toggleAllQuestionSidebar}
                setShowSidebar={setShowSidebar}
                cameraStatus={CAMERA_STATUS}
                recordWebcam={recordWebcam}
                resetTimer={resetTimerForQuestionDrawer}
                setSelectedTabIndex={setSelectedTabIndex}
                setShowReplyBox={setShowReplyBox}
                setAudioOrVideoResponseTye={setAudioOrVideoResponseTye}
                audioOrVideoResponseType={audioOrVideoResponseType}
                recorderControls={recorderControls}
                setShowAudioPreview={setShowAudioPreview}
                showRecordbtnForSelectResponseType={
                  showRecordbtnForSelectResponseType
                }
                setShowRecordbtnForSelectResponseType={
                  setShowRecordbtnForSelectResponseType
                }
              />
            )}
          </div>
        )}
      <TimeOutModal
        showModal={showTimeoutModal}
        setShowModal={setShowTimeoutModal}
      />
      <ReRecordModal
        showModal={showReRecordModal}
        setShowModal={setShowReRecordModal}
        onClick={retakeVideo}
      />

      <RecordingLimitModal
        showModal={showRecordLimitModal}
        setShowModal={setShowRecordLimitModal}
      />
      <VidelDeleteModal
        showModal={showVideoDeleteModal}
        setShowModal={setShowVideoDeleteModal}
      />
      <SelectAudioVideoModal
        showModal={selectAudioVideo}
        setShowModal={setSelectAudioVideo}
        setResponseType={setAudioOrVideoResponseTye}
        audioOrVideoResponseType={audioOrVideoResponseType}
        showRecordbtnForSelectResponseType={showRecordbtnForSelectResponseType}
        setShowRecordbtnForSelectResponseType={
          setShowRecordbtnForSelectResponseType
        }
        recordWebcam={recordWebcam}
        setSelectedTabIndex={setSelectedTabIndex}
        selectedTabIndex={selectedTabIndex}
      />
      {showDonePopupModal && <TourDonePopupModal />}
    </div>
  );
};

export default memo(InterviewPreparationComp);
