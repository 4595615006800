/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */
import Slider from "rc-slider";
import React, { useEffect, useState } from "react";
import { Download, Volume2 } from "react-feather";
import { Tooltip } from "react-tooltip";
import playImage from "../../../../../../../assets/icons/Play.svg";
import pauseImage from "../../../../../../../assets/icons/pause.svg";
import "./videoCard.scss";

const RecentVideoCard = (props: any) => {
  const { videoRef, videoSourceRef, activeAnswer } = props;
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [volume, setVolume] = useState(10);

  useEffect(() => {
    videoSourceRef.current.src = activeAnswer.video_link;
    videoRef.current.load();
    setPlay(false);
  }, [activeAnswer]);
  const playPauseVideo = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlay(true);
    } else {
      videoRef.current.pause();
      setPlay(false);
    }
  };
  // console.log(activeAnswer);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const remainingSeconds = seconds - hours * 3600 - minutes * 60;
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const onButtonClick = () => {
    fetch(activeAnswer.video_link).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement("a");
        alink.href = fileURL;
        // alink.download = `${data.resource_name}.${data.resource_extension}`;
        alink.download = "video.mp4";
        alink.click();
      });
    });
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentDuration(Math.round(videoRef.current.currentTime));
    }
  };

  const handleVolumeChange = (volume: any) => {
    if (videoRef.current) {
      setVolume(volume);
      videoRef.current.volume = volume;
    }
  };
  const handleSliderTime = (seconds: any) => {
    videoRef.current.currentTime = seconds;
    setCurrentDuration(seconds);
  };
  const checkIfVideoEnded = () => {
    setPlay(false);
  };
  const seekingHandler = () => {
    setLoading(true);
  };
  const seekedHandler = () => {
    setLoading(false);
  };

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("rc-slider") &&
      !e.target.classList.contains("rc-slider-track") &&
      !e.target.classList.contains("rc-slider-rail") &&
      !e.target.classList.contains("rc-slider-step") &&
      !e.target.classList.contains("rc-slider-handle") &&
      !e.target.classList.contains("rc-slider-handle-dragging ") &&
      !e.target.classList.contains("volume__and__download__section") &&
      !e.target.classList.contains("volume__section") &&
      !e.target.classList.contains("volume___controller") &&
      !e.target.classList.contains("volume__slider__div") &&
      !e.target.classList.contains("checkbox-div")
    ) {
      setShowVolumeSlider(false);
    }
  });

  return (
    <div className="mid__content">
      <div className="video__section">
        <div className="video__content">
          <video
            id="my-video"
            ref={videoRef}
            style={{
              transform: "scaleX(-1)",
            }}
            onTimeUpdate={handleTimeUpdate}
            onEnded={checkIfVideoEnded}
            onSeeking={seekingHandler}
            onSeeked={seekedHandler}
          >
            <source
              id="my-source"
              src={activeAnswer.video_link}
              ref={videoSourceRef}
            />
          </video>

          {/* {loading && (
            <div className="video__loading__section">
              <div className="spinner3" />
            </div>
          )} */}
          <div className="video__controller__handle">
            <div className="video__controller__section">
              <div className="play__pause__section">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={play ? "Pause" : "Play"}
                  data-tooltip-place="top"
                >
                  <img
                    src={play ? pauseImage : playImage}
                    className="play__image"
                    alt="play"
                    role="button"
                    onClick={() => playPauseVideo()}
                    aria-hidden
                  />
                </a>
              </div>
              <div className="time__slider__section">
                <span className="time mr-1">
                  {" "}
                  {formatTime(currentDuration)}{" "}
                </span>
                <div className="time__progress__section">
                  <Slider
                    onChange={(nextValues: any) => {
                      handleSliderTime(nextValues);
                    }}
                    value={currentDuration}
                    min={0}
                    max={Number(activeAnswer.duration)}
                    defaultValue={0}
                    step={1}
                  />
                </div>
                <span className="time ml-1">
                  {formatTime(Number(activeAnswer.duration))}{" "}
                  {/* {formatTime(Number(activeAnswer.duration))}{" "} */}
                </span>
              </div>
              <div className="volume__and__download__section">
                {" "}
                <div className="volume__section">
                  {" "}
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Volume"
                    data-tooltip-place="top"
                  >
                    <Volume2
                      className="volume___controller"
                      onClick={() => setShowVolumeSlider(!showVolumeSlider)}
                    />
                  </a>
                  <div
                    className={`volume__slider__div ${
                      showVolumeSlider && "active"
                    }`}
                  >
                    {" "}
                    <Slider
                      vertical
                      onChange={(nextValues: any) => {
                        handleVolumeChange(nextValues);
                      }}
                      value={volume}
                      min={0}
                      max={1}
                      defaultValue={1}
                      step={0.01}
                    />
                  </div>
                </div>
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Download"
                  data-tooltip-place="top"
                >
                  <Download
                    className="download__button"
                    // onClick={() => onButtonClick()}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <Tooltip id="my-tooltip" />
      </div>
    </div>
  );
};

export default RecentVideoCard;
