/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import ReactHtmlParser from "react-html-parser";

import { File, FilePlus, FileText } from "react-feather";

import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";

import "./index.scss";
import logo from "../../../../assets/images/half-logo.png";
import MessageLoading from "./MessageLoading";
import image from "../../../../assets/images/aa-dashboard-banner.png";
import { fileIcons } from "../InputMessage/FileIcons";
import FilesPreview from "../InputMessage/FilesPreview/FilesPreview";

const Message = (props: any) => {
  const { user, ai, message, loading, themeState } = props;

  // console.log("Attachments From Message", message?.attachments);

  return (
    <div className="center__messages">
      <div className={`message__section ${user && "user"} ${ai && "ai"}`}>
        <div className="center__item">
          {user && message?.attachments?.length > 0 && (
            <div>
              <div className="attachments__wrapper">
                <div className="attactments__container">
                  {message?.attachments?.map((file: any, index: any) => {
                           // @ts-ignore 
                           const fileIcon = fileIcons[file?.file_extension] || <File />;
            
                        return (

                              <FilesPreview key={index}  fileIcon={fileIcon} fileName={file?.file_name} fileType={file?.file_extension} fileSize={file.file_size} isRemove={false}/>
                        );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="center__item display__handle">
            {user && <div className="named__avatar"> A</div>}

            {!loading && ai && (
              <div className="image__avatar">
                <img src={themeState.configs.sidebar_closed_logo} alt="" />
              </div>
            )}
            <div className="message__and__buttons__div">
              {user && (
                <div className="message question__fontWeight">
                  {" "}
                  {ReactHtmlParser(message?.message)}{" "}
                </div>
              )}

              {!loading && ai && (
                <div className="message">
                  {" "}
                  {/* {ReactHtmlParser(message?.message)} */}
                  <Markdown rehypePlugins={[rehypeRaw, rehypeHighlight]}>
                    {`${message?.message}`}
                  </Markdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && !user && <MessageLoading />}
    </div>
  );
};

export default Message;
