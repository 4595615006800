/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

import IntoText from "../IntoText/IntoText";
import "./index.scss";
import InputMessage from "../InputMessage/InputMessage";
import Prompt from "../Prompt/Prompt";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { apiDefaultPropmts } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import {
  adduserQuestionToMessageStack,
  askNewQuestionToAi,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import ChatProjects from "../ChatProjects/ChaProjects";
import CreateChatProjectModal from "../ChatProjects/CreateChatProjectModal/CreateChatProjectModal";
import { setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";

const Assist = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });


  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });


  useEffect(() => {
    dispatch(apiDefaultPropmts());
  }, []);

  const fetchHistory = (hashId: any, question: string) => {
     dispatch(setShowProjectInitialScreen(false));
    if (hashId) {
      navigate(`/ai-conversation/${hashId}`);
    } else {
      dispatch(adduserQuestionToMessageStack({message:question,attachments:aiState?.attachments}));
      dispatch(askNewQuestionToAi(question));
      navigate("/ai-conversation");
    }
  };

  return (
    <div className={ `assist__wrapper ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "assist__wrapper__div "}`}>
      <div className="center__section">
        <IntoText />
        <div className="mt-22" />
        <InputMessage />
        <div className="mt-25" />
        <ChatProjects mr0={true} isInAiasistPage={true} showThreedot/>

        {aiState.req_success.prompts && (
          <>
            <span className="prompt__topography"> {aiState.prompts.title}</span>
            <div className="prompt__wrapper chat__custom__scrollbar">
              {/* <div className="left__wrapper"> */}
                {aiState.left_prompts.map((prompt: any, index: number) => {
                  return (
                    <a
                      key={index + 1}
                      className="my-anchor-element"
                      data-tooltip-id="left"
                      data-tooltip-content={
                        prompt.question.length > 90 ? prompt.question : ""
                      }
                    >
                      <Prompt
                        question={prompt.question}
                        hashId={prompt.uniqueId}
                        fetchHistory={fetchHistory}
                        position="left"
                      />
                    </a>
                  );
                })}
              {/* </div> */}
              {/* <div className="right__wrapper"> */}
                {" "}
                {aiState.right_prompts.map((prompt: any, index: number) => {
                  return (
                    <a
                      key={index + 10}
                      className="my-anchor-element"
                      data-tooltip-id="right"
                      data-tooltip-content={
                        prompt.question.length > 90 ? prompt.question : ""
                      }
                    >
                      <Prompt
                        question={prompt.question}
                        hashId={prompt.uniqueId}
                        fetchHistory={fetchHistory}
                        position="right"
                      />
                    </a>
                  );
                })}
              {/* </div> */}
            </div>{" "}
          </>
        )}
      </div>
      <CreateChatProjectModal/>
    </div>
  );
};

export default Assist;
