/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ChevronUp,
  Edit,
  Menu,
  RefreshCcw,
  StopCircle,
} from "react-feather";
import { toast } from "react-toastify";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import Message from "../Message/Message";
import "./index.scss";
import {
  fetchConversation,
  filterQuestion,
  getAiProjects,
  stopGenerating,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import MessageLoading from "../Message/MessageLoading";
import {
  addAnswerToMessageStack,
  addConversationToSidebar,
  askNewQuestionToAi,
  setActiveConversationId,
  setLoaderId,
  setLoadingCurrentAnswer,
  setResponseGenerated,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import CurrentResponse from "../Message/CurrentResponse";
import { renderDynamicUrlFromComponentLevelState } from "../../../../helpers/utils/urlHelper";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";
import NewInputMessage from "../InputMessage/NewInputMessage";
import ProjectChatInitialScreen from "./ProjectChatInitialScreen/ProjectChatInitialScreen";
import { getChatHistory, getFileUrls } from "../../../../helpers/utils/extras";
import { aiModels } from "../../../../helpers/utils/aiModels";
import { getBase64 } from "../../../../helpers/utils/FileHelper";

const ChatingArea = (props: any) => {
  const { setShowSideBar } = props;
  const dispatch = useAppDispatch();
  const bottomRef = useRef<any>(null);
  const params = useParams();
  const [currentResponse, setCurrentResponse] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [stopResponse, setStopResponse] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const currentQuestionIdRef = useRef<string>("");
  
  const eventSourceRef = useRef<EventSource | null>(null);

  const aiState = useAppSelector((store: RootStore) => store.AiSlice);
  const themeState = useAppSelector((store: RootStore) => store.ThemeSlice);
  const authState = useAppSelector((store: RootStore) => store.AuthSlice);
  const configState = useAppSelector((store: RootStore) => store.ThemeSlice);
  const uiSlice = useAppSelector((store: RootStore) => store.UISlice);

  const location = useLocation();
  const projectId = new URLSearchParams(location?.search).get("projectId");
  const pId:any = projectId ? Number(projectId) : null;

  const singleProjectData = aiState?.all_ai_projects?.find(
    (pro: any) => pro?.id === Number(projectId)
  );

  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  const startStream = async (prompt: string,attachments:any) => {
    try {
      abortControllerRef.current = new AbortController();
       // Use the ref value directly from the start
       const questionId = currentQuestionIdRef.current;
      const response = await fetch("https://ai.trainable.us/api/project/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          projectDetails: singleProjectData?.description || "",
          chatHistory: getChatHistory(aiState.messagesStack),
          fileUrl: getFileUrls(attachments)
        }),
        signal: abortControllerRef?.current?.signal,
      });

      if (!response.ok) throw new Error("Failed to start stream");

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let fullResponse = "";

      if (reader) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          fullResponse += chunk;
          if(fullResponse?.length > 400){
            setStopResponse(true)
          }
          setCurrentResponse(prev => prev + chunk);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }

      // Save final response
      dispatch(addAnswerToMessageStack(fullResponse));
      // console.log(questionId);
      setStopResponse(false)
      dispatch(stopGenerating({
        question_id: questionId, // Now using the ref value
        answer: fullResponse
      }));

    } catch (error: any) {
      if (error.name !== "AbortError") {
        toast.error("Error in streaming response");
        console.error("Stream error:", error);
      }
    } finally {
      setCurrentResponse("");
      setCurrentQuestionId("");
      dispatch(setLoadingCurrentAnswer(false));
      dispatch(setResponseGenerated(true));
      abortControllerRef.current = null;
    }
  };

  const startStreamUsingOpeai = (res:any)=>{
    const eventSource: any = new EventSource(
      `${dynamic_url}/ai-stream-chat?question_id=${res.data.data.id}`
    );

    eventSourceRef.current = eventSource;

    let sentence = "";

    eventSource.onmessage = (e: any) => {
      if (e.data === "[DONE]") {
        eventSource.close();

        dispatch(setResponseGenerated(true));
        dispatch(setLoadingCurrentAnswer(false));

        if (sentence) {
          dispatch(addAnswerToMessageStack(sentence));
          setStopResponse(false);
          setCurrentResponse("");
          setCurrentQuestionId("");
        }
      } else {
        const txt = JSON.parse(e.data).choices[0].delta.content;

        if (txt !== undefined) {
          sentence += txt;

          if (sentence.length > 150) {
            setStopResponse(true);
          }
          // sentence.replace(/(?:\r\n|\r|\n)/g, "<br>");
          setCurrentResponse(sentence);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    eventSource.onerror = (e: any) => {
      toast.error(e);
      dispatch(setLoadingCurrentAnswer(false));

      eventSource.close();
    };

  }

  const askQuestionWithAiNew = async () => {
    if (!aiState.question) return;

    dispatch(setLoadingCurrentAnswer(true));
    dispatch(setResponseGenerated(false));

    try {
      const questionData = new FormData();

      // Make sure to use actual state values
      questionData.append("question", aiState.question || "");
      if(pId){

        questionData.append("project_id", pId);
      }
      questionData.append("ai_model", aiState?.ai_model);

      if (aiState.active_conversation_id) {
        questionData.append("conversation_id", aiState.active_conversation_id);
      }
      
      
      // Add files as an array (attachments[])
      aiState.attachments.forEach((file: File) => {
        questionData.append("attachments[]", file); // Ensure it's treated as an array
      });
            
      // console.log("questionData", questionData);
      
      // const questionData = {
      //   question: aiState.question,
      //   project_id: pId,
      //   conversation_id: aiState.active_conversation_id,
      //   ai_model:aiState?.ai_model,
      //   attachments: aiState.attachments
      // };


      const res = await axiosInstance.post(
        `${dynamic_url}/ai-stream-question`,
        questionData,
        {
          headers: {
            "Content-Type": "multipart/form-data", 
          },
        }
      );

      if (!projectId) {
        dispatch(addConversationToSidebar(res.data.data));
      } else {
        dispatch(getAiProjects());
      }

      dispatch(setActiveConversationId(res.data.data));
      setCurrentQuestionId(res.data.data.id);
      currentQuestionIdRef.current = res.data.data.id;

      if(aiState.ai_model === aiModels.deepseek){
        await startStream(aiState.question,res.data.data?.attachments);
      }else if(aiState.ai_model === aiModels.openai){
        startStreamUsingOpeai(res)
      }
      

    } catch (error) {
      toast.error("Failed to start conversation");
      dispatch(setLoadingCurrentAnswer(false));
    } finally {
      dispatch(askNewQuestionToAi(""));
    }
  };
// console.log(aiState.attachments);
  const handleStopeResponseGenerating = ()=>{

    if (currentResponse) {
      dispatch(addAnswerToMessageStack(currentResponse));
      dispatch(stopGenerating({
        question_id: currentQuestionIdRef.current,
        answer: currentResponse
      }));
    }
    setStopResponse(false);
    setCurrentQuestionId("");
    currentQuestionIdRef.current = "";
    setCurrentResponse("");
    dispatch(setResponseGenerated(true));
    dispatch(setLoadingCurrentAnswer(false));

  }

  const handleStopResponse = () => {
    if (abortControllerRef.current && aiState.ai_model === aiModels.deepseek) {
      abortControllerRef.current.abort();
      handleStopeResponseGenerating()
    }
    if (eventSourceRef.current && aiState.ai_model === aiModels.openai) {
      eventSourceRef.current.close();
      handleStopeResponseGenerating()

    }
  };

  useEffect(() => {
    if (params.hashId) {
      dispatch(fetchConversation({ id: params.hashId, projectId: pId }));
    }
  }, [params.hashId, pId, dispatch]);


    // Sync ref with state
    useEffect(() => {
      currentQuestionIdRef.current = currentQuestionId;
    }, [currentQuestionId]);



  const scrolltoTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (aiState.question) {
      askQuestionWithAiNew();
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [aiState.question]);
  


  return (
    <div className="chating__area__section">
      <div className="mobile__view__nav__bar">
        <Menu className="icon" onClick={() => setShowSideBar(true)} />
        <span className="ai_assist">AI Assist</span>
        <Edit className="icon" />
      </div>

      {uiSlice?.showProjectInittialScreen ? (
        <ProjectChatInitialScreen singleProjectData={singleProjectData} />
      ) : (
        <>
          <div className="messaging__list__area">
            {aiState.messagesStack.map((message: any) => (
              <Message
                key={message.id}
                themeState={themeState}
                user={message?.user}
                ai={message?.ai}
                message={message}
              />
            ))}

            {currentResponse && (
              <CurrentResponse
                message={currentResponse}
                themeState={themeState}
              />
            )}

            {aiState.loading.question && !aiState.loaderId && <MessageLoading />}
            <div ref={bottomRef} />
          </div>

          <button
            type="button"
            onClick={scrolltoTop}
            className={`chat__scroll__button ${
              authState.tac_user?.role === roles.USER &&
              configState.configs.membership_enabled === Domain.TAA &&
              "chat__scroll__button__style"
            }`}
          >
            <ChevronUp className="chat_up__icon" />
          </button>

          <div className="fixed__button__wrapper">
            {(aiState.loading.question || aiState.loading.current_answer) && (
              <div className="fixed__response__stop__button">
                {stopResponse ? (
                  <button type="button" onClick={handleStopResponse}>
                    <StopCircle className="refresh__icon" /> Stop generating
                  </button>
                ) : (
                  <button type="button">
                    <RefreshCcw className="refresh__icon" /> Generating...
                  </button>
                )}
              </div>
            )}

            {aiState.response_generated && !aiState.loading.current_answer && (
              <div className="fixed__response__stop__button">
                <button type="button">
                  <Icon icon="fluent-mdl2:completed" className="tick__icon" />
                  Response ready
                </button>
              </div>
            )}
          </div>

          <div className="fixed__input__message">
            <div className="width__padding">
              <NewInputMessage />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChatingArea;