/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { ChevronDown } from "react-feather";
import "./index.scss";
import { useEffect, useRef, useState } from "react";

const SelectDropDown = (props: any) => {
  const { data, selectedValue, handleOption,isDwopdownBg,paddingHandle,isAA,isTAC ,isOptionInTop} = props;
  const [active, setActive] = useState(false);
  const dropdownRef: any = useRef(null);
  const customHanldOption = (d: any) => {
    setActive(false);
    handleOption(d);
  };

  const handleClickOutSide = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setActive(false);
      
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    // return ()=>{
    //   document.addEventListener("mousedown",handleClickOutSide);
    // }
  }, []);
  const toggleDropdown  = ()=>{
    setActive(!active)
  }
  return (
    <div
      className={`drop_down_wrapper ${paddingHandle && "paddingHandle"}`}
      ref={dropdownRef}

    >
      <button type="button"  className="faq__header__section"       onClick={toggleDropdown}>
        <span className="selected__option"> {selectedValue}</span>
        <ChevronDown className="down__icon" />
      </button>

      <div className={`select__options ${active && "active"} ${isDwopdownBg && "isDropdownBg"} ${isAA && "aabg"} ${isTAC && "tacBg"} ${isOptionInTop && "isOptionInTop"}`}>
        <ul className="optioin__ol">
          {data.map((d: any) => {
            return (
              <li
                role="button"
                aria-hidden
                key={d.id}
                className="option"
                onClick={() => customHanldOption(d)}
              >
                {d.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SelectDropDown;
