/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useEffect, useRef, useState } from "react";
import "./index.scss";
import AudioPlayer from "../../../../../shared/AudioPlayer/AudioPlayer";

const PreviewRecordedAudio = ({ blob, audioDuration }: any) => {
  const [url, setUrl] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [duration, setDuration] = useState<number | null>(
    audioDuration ? Number(audioDuration) : null
  );

  useEffect(() => {
    if (!blob) return;

    const newUrl = URL.createObjectURL(blob);

    setUrl((prevUrl) => {
      if (prevUrl) URL.revokeObjectURL(prevUrl); // Revoke only after setting new URL
      return newUrl;
    });

    return () => {
      URL.revokeObjectURL(newUrl); // Cleanup on unmount
    };
  }, [blob]);

  useEffect(() => {
    const handleMetadataLoaded = () => {
      if (audioRef.current) {
        setDuration(audioRef.current.duration);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("loadedmetadata", handleMetadataLoaded);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener(
          "loadedmetadata",
          handleMetadataLoaded
        );
      }
    };
  }, [url]); // Depend on url to re-bind when it changes

  console.log("Current Audio URL:", url, "Duration:", duration);

  return (
    <div className="preview__audio__wrapper">
      <div className="preview__audio__container answer__audio__player">
        {url && duration ? (
          <AudioPlayer key={url} duration={duration} url={url} AudiPlayerStyleHanlde />
        ) : url ? (
          <audio key={url} ref={audioRef} src={url} controls />
        ) : null}
      </div>
    </div>
  );
};

export default memo(PreviewRecordedAudio);
