import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Modal, { ModalBody, ModalHeader } from "../../../../../shared/Modals/Modal"
import TextField from "../../../../../shared/Inputs/TextField/TextField"
import TextArea from "../../../../../shared/Inputs/TextArea/TextArea"
import { formOptions } from "./helper"
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons"
import { RootStore, useAppDispatch, useAppSelector } from "../../../../../store/store"
import { roles } from "../../../../../helpers/utils/Constants/roles"
import { Domain } from "../../../../../helpers/utils/DomainSwitcher/domains"
import { setShowCreateChatProjectModal } from "../../../../../store/features/UI/UISlice"
import { addAiProjects, editAiProjects } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi"
import { setIsEditProject } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice"

const CreateChatProjectModal = (props:any) => {

    const authState: any = useAppSelector((store: RootStore) => {
      return store.AuthSlice;
    });

    const aiState: any = useAppSelector((store: RootStore) => {
          return store.AiSlice;
    });

    const configState: any = useAppSelector((store: RootStore) => {
      return store.ThemeSlice;
    });

    const uiSlice:any = useAppSelector((store)=>store.UISlice);

    const dispatch = useAppDispatch();
  
  // Validate is TAA user or not 
    const isTAAUser = authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA;

   
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors },
    } = useForm<any>(formOptions);

    const closeModal = ()=>{
      reset()
      dispatch(setShowCreateChatProjectModal(false));
      dispatch(setIsEditProject());
    }

    const onSubmit = (values: any) => {
      // console.log(values);
      if(!aiState?.isProjectEdit){
        dispatch(addAiProjects({data:values})).then(()=>closeModal())
      }

      else{
        
        dispatch(editAiProjects({data:values,projectId:aiState?.single_ai_project?.id})).then(()=>{
        closeModal()
        })
      }
    }
        
      const onError = (error: any) => {
        console.log("ERROR:::", error);
      };


      useEffect(()=>{
        if(aiState?.isProjectEdit){
          dispatch(setShowCreateChatProjectModal(true));
          setValue("name",aiState?.single_ai_project?.name);
          setValue("description",aiState?.single_ai_project?.description);
        }

      },[aiState?.isProjectEdit])

       
  return (
 
    <Modal show={uiSlice.showCreateChatProjectModal} setShow={closeModal} onClick={closeModal} bluishBgModal={isTAAUser}>
    <ModalHeader title={aiState?.isProjectEdit ? "Edit Project Details": "Add Project Details"} />
    <ModalBody>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="form_row">
          <TextField
            type="text"
            label="Project Name"
            name="name"
            register={register}
            error={errors?.name}
            handleInputStyle={isTAAUser}
          />
        </div>

        <div className="form_row">
          <TextArea
            type="text"
            label="Project details"
            name="description"
            register={register}
            // error={errors?.description}
            handleTextAreaStyle={isTAAUser}
            half
          />
        </div>

        <div className="button__witdh mt-1">
          <PrimaryButton text={aiState?.isProjectEdit ? "Edit":"Create"} type="submit"  loading={aiState?.loading?.add_ai_project} disable={aiState?.loading?.add_ai_project} />
        </div>
      </form>
    </ModalBody>
  </Modal>
  )
}

export default CreateChatProjectModal
