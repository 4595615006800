/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-template */
import { ArrowUp, File,  X } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";
import {
  adduserQuestionToMessageStack,
  askNewQuestionToAi,
  setAttachments,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";
import ChangeAiModelDropdown from "./ChangeAiModelDropdown/ChangeAiModelDropdown";
import { aiModels } from "../../../../helpers/utils/aiModels";
import { formatFileDataArray, formatFileSize } from "../../../../helpers/utils/extras";
import { fileIcons } from "./FileIcons";
import FilesPreview from "./FilesPreview/FilesPreview";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";



const NewInputMessage = (props: any) => {
  const { fromChatBot } = props;
  const dispatch = useAppDispatch();
  const questionRef: any = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState<string>("");
 
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState<string | null>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const isTAC = configState.configs.membership_enabled === Domain.TAC;

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e?.target.files;
    if (!files) return;
  
    const selectedFiles = Array.from(files);
    const newFiles = [...localFiles, ...selectedFiles];
  
    // Check max file count
    if (newFiles.length > 10) {
      setFileError("You can upload a maximum of 10 files.");
      return;
    }
  
    // Check each file size and total size
    const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > 200 * 1024 * 1024) { // 200MB
      setFileError("Total file size cannot exceed 200MB.");
      return;
    }
  
    if (selectedFiles.some(file => file.size > 20 * 1024 * 1024)) { // 20MB
      setFileError("Each file must be 20MB or less.");
      return;
    }
  
    setFileError(null);
    setLocalFiles(newFiles);
  };

  const removeAttachment = (index: number) => {
    const updatedFiles = localFiles.filter((_, i) => i !== index);
    setLocalFiles(updatedFiles);
  
    // Recalculate total size and update error state if needed
    const totalSize = updatedFiles.reduce((sum, file) => sum + file.size, 0);
    if (updatedFiles.length > 10) {
      setFileError("You can upload a maximum of 10 files.");
    } else if (totalSize > 200 * 1024 * 1024) {
      setFileError("Total file size cannot exceed 200MB.");
    } else {
      setFileError(null);
    }
  
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  

const buttonDisable = aiState.loading.question ||
aiState.loading.current_answer ||
input.trim().length === 0 ||  fileError !== null;

  const sbumitQuestion = () => {
    // console.log("Clicked...");
    if (
      aiState.loading.question ||
      aiState.loading.current_answer ||
      input.trim().length === 0
    ) {
      return;
    }
    dispatch(setAttachments(localFiles))
    dispatch(setShowProjectInitialScreen(false));
    dispatch(adduserQuestionToMessageStack({message:input,attachments:formatFileDataArray(localFiles)}));
    dispatch(askNewQuestionToAi(input));
 
    if (!fromChatBot) {
      if (!location.pathname.includes("/ai-conversation")) {
        navigate("/ai-conversation");
      }
    }


    setInput("");
    setLocalFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };


  const resizeTextArea = () => {
    questionRef.current.style.height = "auto";
    questionRef.current.style.height = `${questionRef.current.scrollHeight}px`;
  };
  useEffect(resizeTextArea, [input]);
  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  const handleKeycode = (event: any) => {
    if (event.code === "Enter" || event.code === "enter") {
      event.preventDefault();
      sbumitQuestion();
    }
  };

  // console.log("From New Input ",aiState?.attachments)

  return (
    <div className="new__input__wrapper">
      <div
        className={`new__input__container ${
          isTAC && "tac__new__input__container"
        }`}
      >
        <div className="input__file__preview">
          <div className={`file__previewer ${localFiles?.length > 0 &&"filespaddingHandle"}`}>

              {localFiles.length > 0 && localFiles?.map((file, index) => {
                    const type = file?.name?.split(".").pop()
                    // @ts-ignore 
                    const fileIcon = fileIcons[type] || <File />; // Default File icon

                    return (
                        <FilesPreview key={index} removeAttachment={removeAttachment} index={index} fileIcon={fileIcon} fileName={file?.name} fileType={type} fileSize={file.size} isRemove={true} isTAC={isTAC}/>
                    );
                })}
          </div>
          <ErrorText error={fileError}/>

          <div className="message__input__section">
            <textarea
              ref={questionRef}
              className={`chat__textarea ${
                authState?.tac_user?.role === roles.USER &&
                configState?.configs?.membership_enabled === Domain.TAA &&
                "chat__textarea__input "
              }`}
              value={input}
              rows={1}
              placeholder="Ask anything..."
              onChange={handleInputChange}
              onKeyDown={handleKeycode}
            >
              {" "}
            </textarea>
          </div>
          <div className="input__bottom">
            <div className="bottom__left">
              {
                aiState.ai_model === aiModels.deepseek && (
                  <label
                  htmlFor="file__input"
                  className="file__select plus__file__circle"
                >
                  <input
                    type="file"
                    id="file__input"
                    onChange={handleFileUpload}
                    multiple
                     accept=".pdf,.csv,.txt,.docx,.json,.jpg,.jpeg,.png,.xls,.xlsx"
                    ref={fileInputRef}
                  />
                  <div className="icon__section">
                    <File />
                  </div>
                </label>
                )
              }
            
              <ChangeAiModelDropdown />
            </div>
            <div className="bottom__right">
              <button type="button" disabled={buttonDisable}  onClick={() => sbumitQuestion()}>
                <ArrowUp
                  role="button"
                  aria-hidden
                  className={`right_arrow ${
                    authState?.tac_user?.role === roles.USER &&
                    configState?.configs.membership_enabled === Domain.TAA &&
                    "right__arrow__icon "
                  }`}
                 
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInputMessage;
