import React from "react";
import "./chatbotheader.scss";
import { Menu, X } from "react-feather";
import { useAppDispatch } from "../../../../../store/store";
import { clearChattingScreen, setSelectedProjectId } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { setShowProjectInitialScreen } from "../../../../../store/features/UI/UISlice";

const ChatbotHeader = (props: any) => {
  const { setShowChatBox, setShowQuestionChatSlider } = props;
  const dispatch = useAppDispatch();
  const closePopUpAndClearScreen = () => {
    setShowChatBox(false);
    dispatch(clearChattingScreen());
    dispatch(setShowProjectInitialScreen(false));
    dispatch(setSelectedProjectId(null));
  };

  return (
    <div className="chatbot__section__header__wrapper">
      <div className="chatbot__section__header__container">
        <div className="chatbot__header">
          <Menu
            onClick={() => {
              setShowQuestionChatSlider(true);
            }}
            className="mobile__view__menu__icon"
          />
          <span>AI Chatbot</span>
          <X className="close" onClick={closePopUpAndClearScreen} />
        </div>
      </div>
      <div className="chatbot__header__underline" />
    </div>
  );
};

export default ChatbotHeader;
