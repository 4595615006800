import React, { useEffect, useRef, useState } from "react";
import { RefreshCcw } from "react-feather";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import ReactHtmlParser from "react-html-parser";

import "./mchattingArea.scss";
import logo from "../../../../../../assets/images/half-logo.png";
import tickDoneIcon from "../../../../../../assets/images/tickdone.svg";
import wtickDoneIcon from "../../../../../../assets/images/wtickDoneimg.svg";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/store";
import { renderDynamicUrlFromComponentLevelState } from "../../../../../../helpers/utils/urlHelper";
import {
  addAnswerToMessageStack,
  addConversationToSidebar,
  askNewQuestionToAi,
  setActiveConversationId,
  setLoadingCurrentAnswer,
  setResponseGenerated,
} from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import axiosInstance from "../../../../../../apiConfigs/axiosInstance";

const MobileViewChatbotChattingArea = () => {
  const [showResponseBtn, setShowResponseBtn] = useState(false);
  const dispatch = useAppDispatch();
  const bottomRef = useRef<any>(null);
  const [messageStack, setMessageStack] = useState<any>([]);
  const [currentResponse, setCurrentResponse] = useState("");
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  const askQuestionWithAiNew = async () => {
    const data: any = { question: aiState.question };
    if (aiState.active_conversation_id) {
      data.conversation_id = aiState.active_conversation_id;
    }
    dispatch(setLoadingCurrentAnswer(true));

    const res = await axiosInstance.post(
      `${dynamic_url}/ai-stream-question`,
      data
    );

    dispatch(addConversationToSidebar(res.data.data));
    dispatch(setActiveConversationId(res.data.data));
    const eventSource: any = new EventSource(
      `${dynamic_url}/ai-stream-chat?question_id=${res.data.data.id}`
    );

    let sentence = "";

    eventSource.onmessage = (e: any) => {
      if (e.data === "[DONE]") {
        eventSource.close();

        dispatch(setResponseGenerated(true));
        dispatch(setLoadingCurrentAnswer(false));

        if (sentence) {
          dispatch(addAnswerToMessageStack(sentence));
          setCurrentResponse("");
        }
      } else {
        const txt = JSON.parse(e.data).choices[0].delta.content;

        if (txt !== undefined) {
          sentence += txt;
          setCurrentResponse(sentence);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    eventSource.onerror = (e: any) => {
      toast.error(e);
      dispatch(setLoadingCurrentAnswer(false));
      eventSource.close();
    };
  };

  useEffect(() => {
    if (aiState.question) {
      askQuestionWithAiNew();
      // dispatch(askQuestionWithAi(data));

      setMessageStack([
        ...messageStack,
        { id: uuidv4(), message: aiState.question, user: true, ai: false },
      ]);

      dispatch(askNewQuestionToAi(""));
      dispatch(setResponseGenerated(false));
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [aiState.question]);
  return (
    <div className="mobile__chatting__area">
      <div className="mobile__message__area">
        {aiState.messagesStack.map((message: any) => {
          if (message.user) {
            return (
              <div className="user__question" key={message.id}>
                <div className="user__question__wrapper">
                  <div className="user__first__letter__handle__div">
                    <div className="user__avatar">
                      <div className="first__letter">
                        {" "}
                        {authState.tac_user?.first_name?.charAt(0)}
                      </div>
                    </div>
                  </div>
                  <div className="user__question__div__handle">
                    <div className="user__question__div">
                      {ReactHtmlParser(message?.message)}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div className="chatbot__answer" key={message.id}>
              <div className="chatbot__answer__wrapper">
                <div className="image__width__handle">
                  <div className="image__avatar">
                    <img src={logo} alt="" />
                  </div>
                </div>

                <div className="chatbot__answer__handle">
                  <div className="chatbot__answer">
                    {ReactHtmlParser(message?.message)}{" "}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {currentResponse && currentResponse.length > 0 && (
          <div className="chatbot__answer">
            <div className="chatbot__answer__wrapper">
              <div className="image__width__handle">
                <div className="image__avatar">
                  <img src={logo} alt="" />
                </div>
              </div>

              <div className="chatbot__answer__handle">
                <div className="chatbot__answer">
                  {ReactHtmlParser(currentResponse)}{" "}
                </div>
              </div>
            </div>
          </div>
        )}

        <div ref={bottomRef} />
      </div>

      <div className="fixed__button__wrapper">
        {(aiState.loading.question || aiState.loading.current_answer) && (
          <div className="fixed__response__stop__button">
            <button type="button">
              <RefreshCcw className="refresh__icon" /> Generating response..
            </button>
          </div>
        )}

        {aiState.response_generated && !aiState.loading.current_answer && (
          <div className="fixed__response__stop__button">
            <button type="button">
              <img
                src={
                  themeState.themeMode === "dark" ? tickDoneIcon : wtickDoneIcon
                }
                alt="done"
              />
              Response generated!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileViewChatbotChattingArea;
