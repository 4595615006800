import React from "react";
import "./createChatButton.scss";
import { Plus } from "react-feather";

const CreateChatProjectButton = (props: any) => {
  const { onClick ,mr0,isInAiasistPage} = props;
  return (
    <div className={`create__chat__project__btn__wrapper ${mr0 && "mr0"} ${isInAiasistPage && "mb0"} `}>
    <button
      type="button"
      className={`create__chat__project__btn ${isInAiasistPage && "isInAiasistPageCreateChatProjectBtn"}`}
      onClick={onClick}
    >
      <span> Create New Project</span>
      <div className="circle__plus">
        <Plus/>
      </div>
    </button>
    </div>
  );
};

export default CreateChatProjectButton;
