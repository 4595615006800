import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getInterviewRoles,
  getInterviewQuestionsbyRoleId,
  createVideoId,
  uploadVideoToBunny,
  SubmitInterviewAnswer,
  submitAnswerFeedback,
  deleteUserFeedback,
  getQuestionAnswerById,
  deleteAnswerVideo,
  getPresignedUrlForAudioUpload,
  uploadAudioToAws,
  getPresignedUrlForVideoUploadToAws,
  uploadVideoToAws,
  getPresignedUrlForAudioUploadToAws,
  uploadInterviewAnswerAudioToAws,
} from "./InterviewPreprationApi";

const initialState: any = {
  loading: {
    role: false,
    questions: false,
    presigned_url: false,
    upload_video: false,
    add_answer: false,
    add_feedback: false,
    delete_feedback: false,
    get_answer: false,
    delete_video: false,
    add_audio_feedback: false,
    audio_presigned_url: false,
    upload_audio: false,
    interview_answer_audio_presigned_url:false,
    interview_answer_upload_audio:false
  },
  req_success: {
    role: false,
    questions: false,
    presigned_url: false,
    upload_video: false,
    add_answer: false,
    add_feedback: false,
    delete_feedback: false,
    get_answer: false,
    delete_video: false,
    add_audio_feedback: false,
    audio_presigned_url: false,
    upload_audio: false,
    interview_answer_audio_presigned_url:false,
    interview_answer_upload_audio:false
  },
  roles: [],
  questions: [],
  active_answer: {},
  selected_questions: [],
  presigned_url: "",
  audio_presigned_url: "",
  interview_answer_audio_presigned_url:"",
  notificationData: null,
};

const Interview = createSlice({
  name: "Interview",
  initialState,
  reducers: {
    handleStateforRedirect: (state: any) => {
      state.req_success.role = false;
    },

    selectedQuestion: (state: any) => {
      state.req_success.role = false;
    },
    closeAllAutoCall: (state: any) => {
      state.req_success.presigned_url = false;
      // state.req_success.audio_presigned_url = false;
      state.req_success.upload_video = false;
      // state.req_success.upload_audio = false;
    },
    setAddAsnwertoFalse: (state: any) => {
      state.req_success.add_answer = false;
    },
    setAllResposneSucessToFalse: (state: any) => {
      state.req_success.add_answer = false;
      state.req_success.presigned_url = false;

      state.req_success.upload_video = false;
    },

    setUserSigngleNotificatioData: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.notificationData = payload;
    },
  },
  extraReducers: (builder) => {
    // GET INTERVEW ROLE
    builder.addCase(getInterviewRoles.pending, (state) => {
      state.loading.role = true;
      state.req_success.role = false;
    });
    builder.addCase(
      getInterviewRoles.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.role = false;
        state.req_success.role = true;
        state.roles = payload.data;
      }
    );
    builder.addCase(getInterviewRoles.rejected, (state) => {
      state.loading.role = false;
    });

    // GET INTERVIEW QUESTION ACCORDING TO  ROLE
    builder.addCase(getInterviewQuestionsbyRoleId.pending, (state) => {
      state.loading.questions = true;
      state.req_success.questions = false;
    });
    builder.addCase(
      getInterviewQuestionsbyRoleId.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.questions = false;
        state.req_success.questions = true;
        state.questions = payload.data;
      }
    );
    builder.addCase(getInterviewQuestionsbyRoleId.rejected, (state) => {
      state.loading.questions = false;
    });

        // GET PRESIGNED URL FOR VIDEO UPLOAD
        builder.addCase(getPresignedUrlForVideoUploadToAws.pending, (state) => {
          state.loading.presigned_url = true;
          state.req_success.presigned_url = false;
        });
        builder.addCase(
          getPresignedUrlForVideoUploadToAws.fulfilled,
          (state, { payload }: PayloadAction<any>) => {
            state.loading.presigned_url = false;
            state.req_success.presigned_url = true;
            state.presigned_url = payload.presignedUrl;
          }
        );
        builder.addCase(getPresignedUrlForVideoUploadToAws.rejected, (state) => {
          state.loading.presigned_url = false;
        });

    // GET PRESIGNED URL FOR VIDEO UPLOAD
    builder.addCase(createVideoId.pending, (state) => {
      state.loading.presigned_url = true;
      state.req_success.presigned_url = false;
    });
    builder.addCase(
      createVideoId.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.presigned_url = false;
        state.req_success.presigned_url = true;
        state.presigned_url = payload.guid;
      }
    );
    builder.addCase(createVideoId.rejected, (state) => {
      state.loading.presigned_url = false;
    });

    // GET PRESIGNED URL FOR AUDIO UPLOAD
    builder.addCase(getPresignedUrlForAudioUpload.pending, (state) => {
      state.loading.audio_presigned_url = true;
      state.req_success.audio_presigned_url = false;
    });
    builder.addCase(
      getPresignedUrlForAudioUpload.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.audio_presigned_url = false;
        state.req_success.audio_presigned_url = true;
        state.audio_presigned_url = payload.presignedUrl;
      }
    );
    builder.addCase(getPresignedUrlForAudioUpload.rejected, (state) => {
      state.loading.audio_presigned_url = false;
    });
        // GET PRESIGNED URL FOR AUDIO UPLOAD TOAWS
        builder.addCase(getPresignedUrlForAudioUploadToAws.pending, (state) => {
          state.loading.interview_answer_audio_presigned_url = true;
          state.req_success.interview_answer_audio_presigned_url = false;
        });
        builder.addCase(
          getPresignedUrlForAudioUploadToAws.fulfilled,
          (state, { payload }: PayloadAction<any>) => {
            state.loading.interview_answer_audio_presigned_url = false;
            state.req_success.interview_answer_audio_presigned_url = true;
            state.interview_answer_audio_presigned_url = payload.presignedUrl;
          }
        );
        builder.addCase(getPresignedUrlForAudioUploadToAws.rejected, (state) => {
          state.loading.interview_answer_audio_presigned_url = false;
        });

       //  VIDEO UPLOAD TO AWS
       builder.addCase(uploadVideoToAws.pending, (state) => {
        state.loading.upload_video = true;
        state.req_success.upload_video = false;
      });
      builder.addCase(
        uploadVideoToAws.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.loading.upload_video = false;
          state.req_success.upload_video = true;
        }
      );
      builder.addCase(uploadVideoToAws.rejected, (state) => {
        state.loading.upload_video = false;
      });

    //  VIDEO UPLOAD TO Bunny net
    builder.addCase(uploadVideoToBunny.pending, (state) => {
      state.loading.upload_video = true;
      state.req_success.upload_video = false;
    });
    builder.addCase(
      uploadVideoToBunny.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.upload_video = false;
        state.req_success.upload_video = true;
      }
    );
    builder.addCase(uploadVideoToBunny.rejected, (state) => {
      state.loading.upload_video = false;
    });

    //  AUDIO UPLOAD TO AWS
    builder.addCase(uploadAudioToAws.pending, (state) => {
      state.loading.upload_audio = true;
      state.req_success.upload_audio = false;
    });
    builder.addCase(
      uploadAudioToAws.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.upload_audio = false;
        state.req_success.upload_audio = true;
      }
    );
    builder.addCase(uploadAudioToAws.rejected, (state) => {
      state.loading.upload_audio = false;
    });

      // INTERVIEW ANSWER AUDIO UPLOAD TO AWS
      builder.addCase(uploadInterviewAnswerAudioToAws.pending, (state) => {
      state.loading.interview_answer_upload_audio = true;
      state.req_success.interview_answer_upload_audio = false;
    });
    builder.addCase(
      uploadInterviewAnswerAudioToAws.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.interview_answer_upload_audio = false;
        state.req_success.interview_answer_upload_audio = true;
      }
    );
    builder.addCase(uploadInterviewAnswerAudioToAws.rejected, (state) => {
      state.loading.interview_answer_upload_audio = false;
    });

    //  ADD ALL ANSWER
    builder.addCase(SubmitInterviewAnswer.pending, (state) => {
      state.loading.add_answer = true;
      state.req_success.add_answer = false;
    });
    builder.addCase(
      SubmitInterviewAnswer.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add_answer = false;
        state.req_success.add_answer = true;
      }
    );
    builder.addCase(SubmitInterviewAnswer.rejected, (state) => {
      state.loading.add_answer = false;
    });

    //  SUBMIT FEEDBACK BY USER
    builder.addCase(submitAnswerFeedback.pending, (state) => {
      state.loading.add_answer = true;
      state.req_success.add_answer = false;
    });
    builder.addCase(
      submitAnswerFeedback.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add_answer = false;
        state.req_success.add_answer = true;
      }
    );
    builder.addCase(submitAnswerFeedback.rejected, (state) => {
      state.loading.add_answer = false;
    });

    //  DELETE USER FEEDBACK
    builder.addCase(deleteUserFeedback.pending, (state) => {
      state.loading.delete_feedback = true;
      state.req_success.delete_feedback = false;
    });
    builder.addCase(
      deleteUserFeedback.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete_feedback = false;
        state.req_success.delete_feedback = true;
      }
    );
    builder.addCase(deleteUserFeedback.rejected, (state) => {
      state.loading.delete_feedback = false;
    });

    //  DELETE USER VIDEO
    builder.addCase(deleteAnswerVideo.pending, (state) => {
      state.loading.delete_video = true;
      state.req_success.delete_video = false;
    });
    builder.addCase(
      deleteAnswerVideo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete_video = false;
        state.req_success.delete_video = true;
      }
    );
    builder.addCase(deleteAnswerVideo.rejected, (state) => {
      state.loading.delete_video = false;
    });

    //  GET QUESTION ANSWER BY ID
    builder.addCase(getQuestionAnswerById.pending, (state) => {
      state.active_answer = {};
      state.loading.get_answer = true;
      state.req_success.get_answer = false;
      state.req_success.audio_presigned_url = false;
      state.req_success.upload_audio = false;
      state.audio_presigned_url = "";
      state.req_success.interview_answer_audio_presigned_url = false;
      state.req_success.interview_answer_upload_audio = false;
      state.interview_answer_audio_presigned_url = "";
    });
    builder.addCase(
      getQuestionAnswerById.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_answer = false;
        state.req_success.get_answer = true;
        state.active_answer = payload.data;
      }
    );
    builder.addCase(getQuestionAnswerById.rejected, (state) => {
      state.loading.get_answer = false;
    });

  },
});

export default Interview.reducer;

export const {
  handleStateforRedirect,
  closeAllAutoCall,
  setAddAsnwertoFalse,
  setAllResposneSucessToFalse,
  setUserSigngleNotificatioData,
} = Interview.actions;
