import { useState } from "react";
import ChatSidebar from "../ChatSidebar/ChatSidebar";
import ChatingArea from "../ChatingArea/ChatingArea";
import "./index.scss";
import MobileSidebar from "../ChatSidebar/MobileSidebar";
import CreateChatProjectModal from "../ChatProjects/CreateChatProjectModal/CreateChatProjectModal";
import ChatProjectsInitialScreen from "../ChatProjects/ChatProjectsInitialScreen/ChatProjectsInitialScreen";
import { useAppDispatch, useAppSelector } from "../../../../store/store";

const Conversation = () => {
  const [hashId, setHashId] = useState<string>("111-1111-1111-111-111");
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
    const uiSlice:any = useAppSelector((store)=>store.UISlice);
  
    const dispatch = useAppDispatch();
    
  return (
    <div className="conversation__wrappper">
      <ChatSidebar setHashId={setHashId} />
      <MobileSidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        setHashId={setHashId}
      />

      {/* for a single chat  */}
      <div className="margin__for__chating">
        <ChatingArea hashId={hashId} setShowSideBar={setShowSideBar} />
      </div>

      {/* for Project chat ui only  */}
      {/* <div className="margin__for__chating chatting__area__with">
      
        <ChatProjectsInitialScreen/>
      </div> */}
      <CreateChatProjectModal />
    </div>
  );
};

export default Conversation;
