/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useRef, useState } from "react";
import { File, RefreshCcw, StopCircle } from "react-feather";
import ReactHtmlParser from "react-html-parser";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import rehypeRaw from "rehype-raw";

import "./conversation.scss";
import logo from "../../../../../assets/images/half-logo.png";
import tickDoneIcon from "../../../../../assets/images/tickdone.svg";
import wtickDoneIcon from "../../../../../assets/images/wtickDoneimg.svg";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  addAnswerToMessageStack,
  addConversationToSidebar,
  askNewQuestionToAi,
  setActiveConversationId,
  setLoadingCurrentAnswer,
  setResponseGenerated,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromComponentLevelState } from "../../../../../helpers/utils/urlHelper";
import CurrentResponse from "./CurrentResponse";
import { getAiProjects, stopGenerating } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { getChatHistory, getFileUrls } from "../../../../../helpers/utils/extras";
import { aiModels } from "../../../../../helpers/utils/aiModels";
import { fileIcons } from "../../../AiAssist/InputMessage/FileIcons";
import FilesPreview from "../../../AiAssist/InputMessage/FilesPreview/FilesPreview";

const ChatbotConversation = (props:any) => {
  const {singleProjectData} = props;
  const dispatch = useAppDispatch();
  const bottomRef = useRef<any>(null);
 const [currentResponse, setCurrentResponse] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [stopResponse, setStopResponse] = useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const currentQuestionIdRef = useRef<string>("");

  const eventSourceRef = useRef<EventSource | null>(null);

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

      // Sync ref with state
      useEffect(() => {
        currentQuestionIdRef.current = currentQuestionId;
      }, [currentQuestionId]);

  const startStream = async (prompt: string,attachments:any) => {
    try {
      abortControllerRef.current = new AbortController();
       // Use the ref value directly from the start
       const questionId = currentQuestionIdRef.current;
      const response = await fetch("https://ai.trainable.us/api/project/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt,
          projectDetails: singleProjectData?.description || "",
          chatHistory: getChatHistory(aiState.messagesStack),
          fileUrl: getFileUrls(attachments)
        }),
        signal: abortControllerRef?.current?.signal,
      });

      if (!response.ok) throw new Error("Failed to start stream");

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let fullResponse = "";

      if (reader) {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          fullResponse += chunk;
          if(fullResponse?.length > 400){
            setStopResponse(true)
          }
          setCurrentResponse(prev => prev + chunk);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }

      // Save final response
      dispatch(addAnswerToMessageStack(fullResponse));
      // console.log(questionId);
      setStopResponse(false)
      dispatch(stopGenerating({
        question_id: questionId, // Now using the ref value
        answer: fullResponse
      }));

    } catch (error: any) {
      if (error.name !== "AbortError") {
        toast.error("Error in streaming response");
        console.error("Stream error:", error);
      }
    } finally {
      setCurrentResponse("");
      setCurrentQuestionId("");
      dispatch(setLoadingCurrentAnswer(false));
      dispatch(setResponseGenerated(true));
      abortControllerRef.current = null;
    }
  };
      
    const startStreamUsingOpeai = (res:any)=>{
      const eventSource: any = new EventSource(
        `${dynamic_url}/ai-stream-chat?question_id=${res.data.data.id}`
      );
  
      eventSourceRef.current = eventSource;
  
      let sentence = "";
  
      eventSource.onmessage = (e: any) => {
        if (e.data === "[DONE]") {
          eventSource.close();
  
          dispatch(setResponseGenerated(true));
          dispatch(setLoadingCurrentAnswer(false));
  
          if (sentence) {
            dispatch(addAnswerToMessageStack(sentence));
            setStopResponse(false);
            setCurrentResponse("");
            setCurrentQuestionId("");
          }
        } else {
          const txt = JSON.parse(e.data).choices[0].delta.content;
  
          if (txt !== undefined) {
            sentence += txt;
  
            if (sentence.length > 150) {
              setStopResponse(true);
            }
            // sentence.replace(/(?:\r\n|\r|\n)/g, "<br>");
            setCurrentResponse(sentence);
            bottomRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      };
      eventSource.onerror = (e: any) => {
        toast.error(e);
        dispatch(setLoadingCurrentAnswer(false));
  
        eventSource.close();
      };
  
    }

      const askQuestionWithAiNew = async () => {
        if (!aiState.question) return;
    
        dispatch(setLoadingCurrentAnswer(true));
        dispatch(setResponseGenerated(false));
    
        try {
          const questionData = new FormData();

          // Make sure to use actual state values
          questionData.append("question", aiState.question || "");
          if(aiState?.selectedProjectID){
    
            questionData.append("project_id", aiState?.selectedProjectID);
          }
          questionData.append("ai_model", aiState?.ai_model);
    
          if (aiState.active_conversation_id) {
            questionData.append("conversation_id", aiState.active_conversation_id);
          }

                // Add files as an array (attachments[])
      aiState.attachments.forEach((file: File) => {
        questionData.append("attachments[]", file); // Ensure it's treated as an array
      });
          
          // Create new question entry
          // const questionData = {
          //   question: aiState.question,
          //   project_id: aiState?.selectedProjectID ,
          //   conversation_id: aiState.active_conversation_id
          // };
          
    
          const res = await axiosInstance.post(
            `${dynamic_url}/ai-stream-question`,
            questionData,
            {
              headers: {
                "Content-Type": "multipart/form-data", 
              },
            }
          );
    
          if (!aiState?.selectedProjectID) {
              dispatch(addConversationToSidebar(res.data.data));
          } else {
            dispatch(getAiProjects());
          }
    
          dispatch(setActiveConversationId(res.data.data));
          setCurrentQuestionId(res.data.data.id);
          currentQuestionIdRef.current = res.data.data.id;
          if(aiState.ai_model === aiModels.deepseek){
            await startStream(aiState.question,res.data.data?.attachments);
          }else if(aiState.ai_model === aiModels.openai){
            startStreamUsingOpeai(res)
          }
    
        } catch (error) {
          toast.error("Failed to start conversation");
          dispatch(setLoadingCurrentAnswer(false));
        } finally {
          dispatch(askNewQuestionToAi(""));
        }
      };

  const handleStopeResponseGenerating = ()=>{

    if (currentResponse) {
      dispatch(addAnswerToMessageStack(currentResponse));
      dispatch(stopGenerating({
        question_id: currentQuestionIdRef.current,
        answer: currentResponse
      }));
    }
    setStopResponse(false);
    setCurrentQuestionId("");
    currentQuestionIdRef.current = "";
    setCurrentResponse("");
    dispatch(setResponseGenerated(true));
    dispatch(setLoadingCurrentAnswer(false));

  }

  const handleStopResponse = () => {
    if (abortControllerRef.current && aiState.ai_model === aiModels.deepseek) {
      abortControllerRef.current.abort();
      handleStopeResponseGenerating()
    }
    if (eventSourceRef.current && aiState.ai_model === aiModels.openai) {
      eventSourceRef.current.close();
      handleStopeResponseGenerating()

    }
  };

  
    useEffect(() => {
      if (aiState.question) {
        askQuestionWithAiNew();
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    }, [aiState.question]);

  return (
    <div className="chatting__area">
      <div className="message__area">
        {aiState.messagesStack.map((message: any) => {
          if (message.user) {
            return (
              <div className="user__question" key={message.id}>
                   {message?.attachments?.length > 0 && (
                            <div>
                              <div className="attachments__wrapper">
                                <div className="attactments__container">
                                 {message?.attachments?.map((file: any, index: any) => {
                                                         // @ts-ignore 
                                                         const fileIcon = fileIcons[file?.file_extension] || <File />;
                                          
                                                      return (
                               
                                                            <FilesPreview key={index}  fileIcon={fileIcon} fileName={file?.file_name} fileType={file?.file_extension} fileSize={file.file_size} isRemove={false}/>
                                                      );
                                                })}
                                </div>
                              </div>
                            </div>
                          )}
                <div className="user__question__wrapper">
                  <div className="user__first__letter__handle__div">
                    <div className="user__avatar">
                      <div className="first__letter">
                        {authState.tac_user?.first_name?.charAt(0)}
                      </div>
                    </div>
                  </div>
                  <div className="user__question__div__handle">
                    <div className="user__question__div">
                      {/* <p></p> */}
                      {ReactHtmlParser(message?.message)}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div className="chatbot__answer" key={message.id}>
              <div className="chatbot__answer__wrapper">
                <div className="image__width__handle">
                  <div className="image__avatar">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="chatbot__answer__handle">
                  <div className="chatbot__answer">
                    {/* <p>  </p> */}
                    {/* {ReactHtmlParser(message?.message)} */}
                       <Markdown rehypePlugins={[rehypeRaw,rehypeHighlight]}>
                                                {`${message?.message}`}
                                              </Markdown>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {currentResponse && currentResponse.length > 0 && (
          <CurrentResponse currentResponse={currentResponse} />
        )}
        <div ref={bottomRef} />
      </div>

      <div className="fixed__button__wrapper">
        {(aiState.loading.question || aiState.loading.current_answer) && (
          <div className="fixed__response__stop__button">
            {stopResponse ? (
              <button type="button" onClick={handleStopResponse}>
                <StopCircle className="refresh__icon" /> Stop generating
                response
              </button>
            ) : (
              <button type="button">
                <RefreshCcw className="refresh__icon" /> Generating response..
              </button>
            )}
          </div>
        )}
        {aiState.response_generated && !aiState.loading.current_answer && (
          <div className="fixed__response__stop__button">
            <button type="button">
              <img
                src={
                  themeState.themeMode === "dark" ? tickDoneIcon : wtickDoneIcon
                }
                alt="done"
              />
              Response generated!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotConversation;
