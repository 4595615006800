import { X } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import { NewChatButton } from "../NewChatButton/NewChatButton";
import Question from "./Question";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  clearConversation,
  fetchConversation,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { openNewChat } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import ChatProjects from "../ChatProjects/ChaProjects";
import { setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";

const MobileSidebar = (props: any) => {
  const { showSideBar, setShowSideBar, setHashId } = props;
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const handleConversation = (id: string) => {
    if (aiState.loading.question) {
      return;
    }
    navigate(`/ai-conversation/${id}`);
    setHashId(id);
    dispatch(fetchConversation({ id: id }));
  };

  const newChat = () => {
    dispatch(openNewChat());
    navigate("/ai-conversation");
    dispatch(setShowProjectInitialScreen(false));
  };

  const clearAllConversation = () => {
    dispatch(clearConversation());
    navigate("/ai-conversation");
  };

  return (
    <div className={`mobile__view__chat__side__bar ${showSideBar && "active"}`}>
      <X
        className={`cancel__icon ${showSideBar && "active"}`}
        onClick={() => setShowSideBar(false)}
      />
          <ChatProjects mr0/>
      <div className="mt-3" />
      <NewChatButton onClick={newChat} />
      <div className="mt-2" />
      <div className="questions__section chat__custom__scrollbar">
        {aiState.req_success.all_conversation &&
          aiState.all_conversation.map((conversation: any) => {
            return (
              <Question
                key={conversation.uniqueId}
                id={conversation.uniqueId}
                question={conversation.question}
                handleConversation={handleConversation}
                active={conversation.uniqueId === params.hashId}
              />
            );
          })}
      </div>
      <div className="mt-1" />
      <div className="clear__div">
        {" "}
        <button
          type="button"
          className="clear_conversation__button"
          onClick={clearAllConversation}
          disabled={aiState.loading.question}
        >
          Clear conversations
        </button>
      </div>
    </div>
  );
};

export default MobileSidebar;
