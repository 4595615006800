/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
const mapHoursAndMinutes = (timeString: string) => {
  let hours = 0;

  const match = timeString.match(/^(\d+)h\s*(\d+)m$|^(\d+)h$|^(\d+)m$/);

  if (match) {
    if (match[1]) {
      hours += parseInt(match[1], 10);
      hours += parseInt(match[2], 10) / 60;
    } else if (match[3]) {
      hours += parseInt(match[3], 10);
    } else if (match[4]) {
      hours += parseInt(match[4], 10) / 60;
    }
  }

  // Return the output
  return hours;
};

const includesElement = (array1: any[], array2: any[]) => {
  for (const element of array1) {
    if (array2.includes(element)) {
      return true;
    }
  }
  return false;
};
const getChatHistory=(chats:any)=> {
  const formattedChats = [];

  // Iterate through chats and pair questions with answers
  for (let i = 0; i < chats.length; i++) {
    if (chats[i].user && chats[i + 1] && chats[i + 1].ai) {
      formattedChats.push({
        question: chats[i].message || chats[i + 1].question || "",
        answer: chats[i + 1].message || "",
      });
    }
  }

  // Return last 3 conversations or an empty array if none exist
  return formattedChats.length > 3
    ? formattedChats.slice(-3)
    : formattedChats;
}
function getFileUrls(attachments:any) {
  if (!Array.isArray(attachments)) {
      return [];
  }
  return attachments.map(attachment => attachment.file_url);
}
function formatFileDataArray(files:any) {
  if (!Array.isArray(files)) {
      return [];
  }
  const formattedFileData = files.map((file) => {
    return {
      file_name: file?.name,
      file_size: file.size,
      file_extension: file?.name?.split(".").pop(),
      file_url: URL.createObjectURL(file),
    };
  });

  return formattedFileData;
}

function formatFileSize (sizeInBytes:number){
  if (sizeInBytes < 1024) {
      return `${sizeInBytes  } Bytes`;
  } if (sizeInBytes < 1024 * 1024) {
      return `${(sizeInBytes / 1024).toFixed(2)  } KB`;
  } if (sizeInBytes < 1024 * 1024 * 1024) {
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)  } MB`;
  } 
      return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)  } GB`;
  
};



export { mapHoursAndMinutes, includesElement ,getChatHistory,getFileUrls,formatFileDataArray,formatFileSize};
