import React, { useEffect, useState } from "react"
import "./chatProjects.scss"
import { useNavigate } from "react-router-dom"
import CreateChatProjectButton from "./CreateChatProjectButton/CreateChatProjectButton"
import ChatProjectItem from "./ChatProjectItem"
import { setShowCreateChatProjectModal, setShowDeleteProjectModal, setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice"
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store"
import { deleteAiProjects, getAiProjects } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi"
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal"
import { openNewChat, setActiveConversationUniqueId, setSelectedProjectId } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice"

const ChatProjects = (props:any) => {
  const {mr0,isInAiasistPage,showThreedot,fromChatBot,localProjectIdForChatBot,setLocalProjectIdForChatBot} = props;

    const [projectId,setProjectId] = useState<any>();
    const [openProjectId, setOpenProjectId] = useState<number | null>(null);
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const aiState: any = useAppSelector((store: RootStore) => {
      return store.AiSlice;
    });

    const uiSlice:any = useAppSelector((store)=>store.UISlice);

    useEffect(()=>{
      dispatch(getAiProjects())
    },[])

      const closeModal = ()=>{
      dispatch(setShowDeleteProjectModal(false))
        }

      const deleteProject = ()=>{
        dispatch(deleteAiProjects(projectId)).then(()=>{
          closeModal();
          dispatch(setShowProjectInitialScreen(false));
          dispatch(openNewChat());
          navigate("/ai-conversation");
        })
      }
      const handleNavigate =async(e:any,pId:any)=>{
        e.stopPropagation();
        dispatch(setShowProjectInitialScreen(true))
        dispatch(openNewChat());
        if(!fromChatBot){
          navigate(`/ai-conversation?projectId=${pId}`);
          setOpenProjectId((prev:any) => prev === pId ? null : pId);
        }else{
          setLocalProjectIdForChatBot((prev:any)=>  (prev === pId ? pId : pId) );
          dispatch(setActiveConversationUniqueId(null));
        }
       
      }
      useEffect(() => {
        if (localProjectIdForChatBot !== null) {
          dispatch(setSelectedProjectId(localProjectIdForChatBot))
        }
    }, [localProjectIdForChatBot]);

            useEffect(()=>{
              if(!aiState?.loading.add_ai_project && aiState?.req_success.add_ai_project && aiState?.latest_created_project){
                if(!fromChatBot){
                  navigate(`/ai-conversation?projectId=${aiState?.latest_created_project?.id}`);
                   dispatch(openNewChat());
                      dispatch(setShowProjectInitialScreen(true))
                      setOpenProjectId(null);
                }
                // else{
                //   dispatch(setSelectedProjectId(localProjectIdForChatBot))
                //   setLocalProjectIdForChatBot(aiState?.latest_created_project?.id)
                // }
              }
        
            },[aiState?.loading.add_ai_project,aiState?.req_success.add_ai_project])

  return (

    <div className="chat__projects__wrapper">
      <div className="chat__projects__container">
           <div className={`${isInAiasistPage && "isInAiasistPage"}`}>
              <CreateChatProjectButton onClick={()=>   dispatch(setShowCreateChatProjectModal(true))} mr0={mr0} isInAiasistPage={isInAiasistPage}/>
              <h3 className="cp__title"> Projects</h3>
           </div>
            <div className={`chat__projects__lists chat__custom__scrollbar ${aiState?.all_ai_projects?.length > 3 && "height__scroll" }`}>
                {
                   aiState?.all_ai_projects?.map((item:any)=>(
                      <ChatProjectItem 
                      key={item?.id}
                      onClick={handleNavigate}
                      showThreedot={showThreedot}
                      data={item}
                      setProjectId={setProjectId}
                      openProjectId={openProjectId}
                      setOpenProjectId={setOpenProjectId}
                      isInAiasistPage={isInAiasistPage}
                      fromChatBot={fromChatBot}
                      localProjectIdForChatBot={localProjectIdForChatBot}
                      setLocalProjectIdForChatBot={setLocalProjectIdForChatBot}
                      />
                    ))
                }

               
            </div>
      </div>
      <DeleteModal showModal={uiSlice.showProjectDeleteModal} setShowModal={closeModal} loading={aiState?.loading?.delete_ai_project} onClick={deleteProject}/>
    </div>
  )
}

export default ChatProjects
