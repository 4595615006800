/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useRef, useState } from "react";
import "./mViewChatMessageTextarea.scss";
import {
  ArrowRight,
  Delete,
  Edit,
  Edit2,
  Plus,
  Trash2,
  X,
} from "react-feather";
import whiteChatbotArrow from "../../../../../../assets/images/gray-chatbor-arrow.svg";
import darkChatbotArrow from "../../../../../../assets/images/dark-chatbot-arrow.svg";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/store";
import {
  adduserQuestionToMessageStack,
  askNewQuestionToAi,
  openNewChat,
} from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { clearConversation } from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { setShowProjectInitialScreen } from "../../../../../../store/features/UI/UISlice";

const MobileViewChatMessageTextArea = () => {
  const [showEditDeleteDropdown, setShowEditDeleteDropdown] = useState(false);
  const editDeleteDropDownRef: any = useRef(null);
  const newChatIconRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const [input, setInput] = useState<string>("");
  const questionRef: any = useRef();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const resizeTextArea = () => {
    questionRef.current.style.height = "auto";
    questionRef.current.style.height = `${questionRef.current.scrollHeight}px`;
  };
  useEffect(resizeTextArea, [input]);
  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  const sbumitQuestion = () => {
    if (
      aiState.loading.question ||
      aiState.loading.current_answer ||
      input.trim().length === 0
    ) {
      return;
    }

    dispatch(adduserQuestionToMessageStack({message:input,attachments:aiState?.attachments}));
    dispatch(askNewQuestionToAi(input));
    setInput("");
  };

  const clearAllConversation = () => {
    dispatch(clearConversation());
  };

  const newChat = () => {
    dispatch(openNewChat());
        dispatch(setShowProjectInitialScreen(false));
  };

  const handleClickOutSide = (event: any) => {
    if (
      editDeleteDropDownRef.current &&
      !editDeleteDropDownRef.current.contains(event.target)
    ) {
      if (
        newChatIconRef.current &&
        !newChatIconRef.current.contains(event.target)
      ) {
        setShowEditDeleteDropdown(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);
  return (
    <div className="mobile__chatbot__message__input__section">
      <div className="text__area__handle">
        <textarea
          ref={questionRef}
          className="mobile__chatbot__chat__textarea"
          rows={1}
          value={input}
          placeholder="Enter your message.."
          onChange={handleInputChange}
        >
          {" "}
        </textarea>

        <div className="right__arrow">
          <img
            src={
              themeState.themeMode === "dark"
                ? darkChatbotArrow
                : whiteChatbotArrow
            }
            alt="Chat Arrow"
            role="button"
            aria-hidden
            onClick={() => sbumitQuestion()}
          />
        </div>
      </div>

      <div className="edit__delete__controls__dropdown" ref={newChatIconRef}>
        <div
          className="add__chat__circle"
          aria-hidden
          onClick={() => setShowEditDeleteDropdown(!showEditDeleteDropdown)}
        >
          {showEditDeleteDropdown ? <X /> : <Plus />}
        </div>
        {showEditDeleteDropdown && (
          <div className="edit__delete__dropdown" ref={editDeleteDropDownRef}>
            <div
              className="ai__chat__delete"
              onClick={clearAllConversation}
              role="button"
              aria-hidden
            >
              <Trash2 />
            </div>
            <div
              className="ai__chat__edit"
              onClick={newChat}
              role="button"
              aria-hidden
            >
              <Edit />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileViewChatMessageTextArea;
