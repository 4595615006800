import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  activeLinks: [],
  showCreateChatProjectModal:false,
  showProjectDeleteModal:false,
  showProjectInittialScreen:localStorage.getItem("showProjectInittialScreen") 
  ? JSON.parse(localStorage.getItem("showProjectInittialScreen")!) 
  : true,
};
// ADD about;
const UISlice = createSlice({
  name: "UISlice",
  initialState,
  reducers: {
    addActiveLinks: (state: any, { payload }: PayloadAction<any>) => {
      state.activeLinks.push(payload);
    },
    removeActiveLinks: (state: any, { payload }: PayloadAction<any>) => {
      const temp = state.activeLinks.filter((e: any) => {
        return e !== payload;
      });
      state.activeLinks = temp;
    },
    setShowCreateChatProjectModal:(state:any,{payload})=>{
      state.showCreateChatProjectModal=payload;
    },
    setShowDeleteProjectModal:(state:any,{payload})=>{
      state.showProjectDeleteModal=payload;
    },
    setShowProjectInitialScreen:(state:any,{payload})=>{
      state.showProjectInittialScreen=payload;
      localStorage.setItem("showProjectInittialScreen", JSON.stringify(payload)); 
    },
  },
  extraReducers: (builder) => {},
});

export const { addActiveLinks, removeActiveLinks,setShowCreateChatProjectModal,setShowDeleteProjectModal,setShowProjectInitialScreen } = UISlice.actions;
export default UISlice.reducer;
