import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  apiDefaultPropmts,
  filterQuestion,
  fetchConversation,
  getRecentSearch,
  clearConversation,
  getAiProjects,
  addAiProjects,
  deleteAiProjects,
  editAiProjects,
  changeAiModel,
} from "./AiAssistApi";

const initialState: any = {
  loading: {
    prompts: false,
    question: false,
    conversation: false,
    all_conversation: false,
    clear_conversation: false,
    current_answer: false,
    add_ai_project:false,
    delete_ai_project:false,
    edit_ai_project:false,

  },
  req_success: {
    prompts: false,
    question: false,
    conversation: false,
    all_conversation: false,
    clear_conversation: false,
    current_answer: false,
    add_ai_project:false,
    delete_ai_project:false,
    edit_ai_project:false,
  },
  response_generated: false,
  prompts: [],
  left_prompts: [],
  right_prompts: [],
  conversations: [],
  active_conversation_id: null,
  all_conversation: [],
  loaderId: null,

  messagesStack: [],
  question: "",

  ai_projects:[],
  all_ai_projects:[],
  single_ai_project:{},
  isProjectEdit:false,
  project_delete_id:null,
  selectedProjectID:  null,
  active_conv_unique_id:null,
  convo_unique_id:null,
  latest_created_project:null,
  //  "openai"
  ai_model:"deepseek" ,
  attachments: [],
};

const AiSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    adduserQuestionToMessageStack: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.messagesStack = [
        ...state.messagesStack,
        { id: uuidv4(), message: payload?.message, user: true ,attachments:payload?.attachments},
      ];
    },
    addAnswerToMessageStack: (state: any, { payload }: PayloadAction<any>) => {
      state.messagesStack = [
        ...state.messagesStack,
        {
          id: uuidv4(),
          message: payload,
          ai: true,
          // questionId: payload.data.id,
          // question: payload.data.question,
        },
      ];
    },
    openNewChat: (state: any) => {
      state.messagesStack = [];
      state.active_conversation_id = null;
      state.convo_unique_id=null;
      state.response_generated = false;
      state.loading.add_ai_project =false;
      state.req_success.add_ai_project =false;
      state.latest_created_project = null;
    },
    setLoaderId: (state: any, { payload }: PayloadAction<any>) => {
      state.loaderId = payload;
    },
    askNewQuestionToAi: (state: any, { payload }: PayloadAction<any>) => {
      state.question = payload;
    },
    setAttachments: (state, { payload }: PayloadAction<any>) => {
      state.attachments = payload;
    },
    setActiveConversationId: (state: any, { payload }: PayloadAction<any>) => {
      state.active_conversation_id = payload?.conversation_id;
      state.convo_unique_id = payload?.uniqueId
    },
    setActiveConversationUniqueId: (state: any, { payload }: PayloadAction<any>) => {
      state.active_conv_unique_id = payload;
    },
    setLoadingCurrentAnswer: (state: any, { payload }: PayloadAction<any>) => {
      state.loading.current_answer = payload;
    },
    setResponseGenerated: (state: any, { payload }: PayloadAction<any>) => {
      state.response_generated = payload;
    },
    clearChattingScreen: (state: any) => {
      state.messagesStack = [];
      state.all_conversation = [];
      state.active_conversation_id = null;
      state.convo_unique_id=null;
      state.response_generated = false;
    },

    addConversationToSidebar: (state: any, { payload }: PayloadAction<any>) => {
      let exist = false;
      state.all_conversation.forEach((conv: any) => {
        if (conv.uniqueId === payload.uniqueId) {
          exist = true;
        }
      });
      if (!exist) {
        const latestConv: any = {
          question: payload.question,
          uniqueId: payload.uniqueId,
        };
        state.all_conversation = [latestConv, ...state.all_conversation];
      }
    },

    getSingleAiProject:(state,{payload}:PayloadAction<any>)=>{
      state.isProjectEdit = true;
      // console.log(payload);
      state.single_ai_project= payload;
    },
    setIsEditProject:(state)=>{
      state.isProjectEdit = false;
      state.single_ai_project= {};
    },
    setProjectDeleteId:(state,{payload})=>{
      state.project_delete_id = payload;
    }
    ,
    setSelectedProjectId:(state,{payload}:PayloadAction<null | number>)=>{
      // console.log("Payload... here",payload);
      state.selectedProjectID = payload;
    }
  },
  extraReducers: (builder) => {
    // GET DEFAULT PROMPTS AND RECENT CONVERSATION
    builder.addCase(apiDefaultPropmts.pending, (state) => {
      state.loading.prompts = true;
      state.req_success.prompts = false;
    });
    builder.addCase(
      apiDefaultPropmts.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.prompts = false;
        state.req_success.prompts = true;
        const leftPrompts: any = [];
        const rightPrompts: any = [];
        state.prompts = payload.data;
        payload?.data.prompts.forEach((prompt: any, index: number) => {
          if (index === 0 || index % 2 === 0) {
            leftPrompts.push(prompt);
          } else {
            rightPrompts.push(prompt);
          }
        });
        state.left_prompts = leftPrompts;
        state.right_prompts = rightPrompts;
        state.messagesStack = [];
        state.conversations = [];
        state.active_conversation_id = null;
        state.convo_unique_id=null;
        state.all_conversation = [];
      }
    );
    builder.addCase(apiDefaultPropmts.rejected, (state) => {
      state.loading.prompts = false;
    });

    // FILTER QUESTION WITH AI

    builder.addCase(filterQuestion.pending, (state) => {
      state.loading.question = true;
      state.req_success.question = false;
    });
    builder.addCase(
      filterQuestion.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.req_success.question = true;

        const filteredAnser = {
          id: uuidv4(),
          conversation_id: payload.data.conversation_id,
          message: payload.data.answer,
          ai: true,
          questionId: payload.data.id,
          question: payload.data.question,
        };
        state.messagesStack = state.messagesStack.map((message: any) => {
          if (message.questionId === filteredAnser.questionId && message.ai) {
            return filteredAnser;
          }
          return message;
        });

        state.loading.question = false;
        state.loaderId = null;
      }
    );
    builder.addCase(filterQuestion.rejected, (state) => {
      state.loading.question = false;
    });

    //  GET CONVERSATION BY CONVERSATION ID
    builder.addCase(fetchConversation.pending, (state) => {
      state.loading.conversation = true;
      state.req_success.conversation = false;
    });
    builder.addCase(
      fetchConversation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        // console.log(payload?.data[0]);
        state.messagesStack = [];

        state.loading.conversation = false;
        state.req_success.conversation = true;

        state.active_conversation_id =
          payload?.data[0]?.conversations[0]?.conversation_id;
          state.convo_unique_id =
          payload?.data[0]?.conversations[0]?.uniqueId;
        state.ai_model = payload?.data[0]?.ai_model
        const temp: any = [];
        // console.log("Conversastion data ",payload.data[0].conversations)
        payload.data[0].conversations.forEach((conv: any) => {
          const userdata = {
            id: uuidv4(),
            user: true,
            questionId: conv.id,
            message: conv.question,
            attachments:conv?.attachments
          };
          temp.push(userdata);
          const aidata = {
            id: uuidv4(),
            ai: true,
            questionId: conv.id,
            message: conv.answer,
            question: conv.question,
            conversation_id: conv.conversation_id,
          };
          temp.push(aidata);
        });
        state.messagesStack = [...temp];
        state.conversations = payload?.data;
      }
    );
    builder.addCase(fetchConversation.rejected, (state) => {
      state.loading.conversation = false;
    });

    // get recent_search data or conversation
    builder.addCase(getRecentSearch.pending, (state) => {
      state.loading.all_conversation = true;
      state.req_success.all_conversation = false;
    });
    builder.addCase(
      getRecentSearch.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.all_conversation = false;
        state.req_success.all_conversation = true;
        state.all_conversation = payload.data;
      }
    );
    builder.addCase(getRecentSearch.rejected, (state) => {
      state.loading.all_conversation = false;
    });

    // CLEAR ALL SEARCH DATA OR CONVERSATION
    builder.addCase(clearConversation.pending, (state) => {
      state.loading.clear_conversation = true;
      state.req_success.clear_conversation = false;
    });
    builder.addCase(
      clearConversation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.clear_conversation = false;
        state.req_success.clear_conversation = true;
        state.messagesStack = [];
        state.all_conversation = [];
        state.active_conversation_id = null;
        state.convo_unique_id=null;
        state.response_generated = false;
      }
    );
    builder.addCase(clearConversation.rejected, (state) => {
      state.loading.clear_conversation = false;
    });

    // for projects section api slice implementation starts here
    
    // GET AI PROJECTS
    builder.addCase(getAiProjects.pending, (state) => {
      
    });
    builder.addCase(
      getAiProjects.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.ai_projects = payload?.data;
        state.all_ai_projects= payload?.data;
      }
    );
    builder.addCase(getAiProjects.rejected, (state) => {
      
    });

    // ADD AI PROJECTS
    builder.addCase(addAiProjects.pending, (state) => {
      state.loading.add_ai_project = true;
      state.req_success.add_ai_project = false;
  
    });
    builder.addCase(
      addAiProjects.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add_ai_project = false;
        state.req_success.add_ai_project = true;
        // console.log(payload);
        // state.ai_projects = [...state.ai_projects, payload?.data];
        state.all_ai_projects = [payload?.data,...state.all_ai_projects];
        state.latest_created_project = payload?.data
      }
    );
    builder.addCase(addAiProjects.rejected, (state) => {
      state.loading.ai_project = false;
      state.req_success.ai_project = false;
      
    });
    // Delete AI PROJECTS
    builder.addCase(deleteAiProjects.pending, (state) => {
      state.loading.delete_ai_project = true;
      state.req_success.delete_ai_project = false;
  
    });
    builder.addCase(
      deleteAiProjects.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        // console.log(payload);
        state.loading.delete_ai_project = false;
        state.req_success.delete_ai_project = true;
       
        // state.ai_projects = state.ai_projects.filter((project:any )=> project?.id !== payload?.id);
        state.all_ai_projects = state.all_ai_projects.filter((project:any) => project?.id !== payload?.id);
      }
    );
    builder.addCase(deleteAiProjects.rejected, (state) => {
      state.loading.delete_ai_project = false;
      state.req_success.delete_ai_project = false;
      
    });
    // Edit AI PROJECTS
    builder.addCase(editAiProjects.pending, (state) => {
      state.loading.edit_ai_project = true;
      state.req_success.edit_ai_project = false;
      state.loading.add_ai_project = true;
      state.req_success.add_ai_project = false;
    });
    
    builder.addCase(
      editAiProjects.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        // console.log(payload);
        state.loading.edit_ai_project = false;
        state.req_success.edit_ai_project = true;

        state.loading.add_ai_project = false;
        state.req_success.add_ai_project = true;
        
        // Find and update the edited project in both arrays
        const updatedProject = payload?.data;

        
        // Update in all_ai_projects array
        const allProjectIndex = state.all_ai_projects.findIndex(
          (project: any) => project.id === updatedProject.id
        );
        if (allProjectIndex !== -1) {
          state.all_ai_projects[allProjectIndex] = {
            ...state.all_ai_projects[allProjectIndex],
            ...updatedProject
          };
        }
      }
    );
    
    builder.addCase(editAiProjects.rejected, (state) => {
      state.loading.edit_ai_project = false;
      state.req_success.edit_ai_project = false;

      state.loading.add_ai_project = false;
      state.req_success.add_ai_project = false;
    });

    // Change Ai Model 
      builder.addCase(changeAiModel.pending, (state) => {
    
      });
      builder.addCase(
        changeAiModel.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          // console.log(payload);
          state.ai_model = payload?.model
        }
      );
      builder.addCase(changeAiModel.rejected, (state) => {
      
        
      });
  },
});
export const {
  adduserQuestionToMessageStack,
  openNewChat,
  setLoaderId,
  askNewQuestionToAi,
  setAttachments,
  addAnswerToMessageStack,
  setActiveConversationId,
  setLoadingCurrentAnswer,
  addConversationToSidebar,
  setResponseGenerated,
  clearChattingScreen,
  getSingleAiProject,
  setIsEditProject,
  setProjectDeleteId,
  setSelectedProjectId,
  setActiveConversationUniqueId
} = AiSlice.actions;
export default AiSlice.reducer;
