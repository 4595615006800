import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";
import { REQUIRED__TOASTER } from "../../../../../helpers/utils/TosterError";

export const apiDefaultPropmts = createAsyncThunk(
  "apiDefaultPropmts",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(`${dynamic_url}/ai-default-prompts`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const filterQuestion = createAsyncThunk(
  "filterQuestion",
  async (data: any, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/ai-chat`, data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConversation = createAsyncThunk(
  "fetchConversation",
  async ({ id,projectId }: { id: string,projectId?: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
        // Conditionally construct the API URL
        const url = projectId 
        ? `${dynamic_url}/conversation/${id}/${projectId}`
        : `${dynamic_url}/conversation/${id}`;

      const res = await axiosInstance.get(url);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecentSearch = createAsyncThunk(
  "getRecentSearch",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/ai-searches`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearConversation = createAsyncThunk(
  "clearConversation",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/clear-searches`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const stopGenerating = createAsyncThunk(
  "stopGenerating",
  async (
    data: { question_id: string; answer: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/ai-update-answer`,
        data
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// for projects section api implementation starts here 

// GET ALL AI PROJECTS
export const getAiProjects = createAsyncThunk(
  "getAiProjects",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/ai-projects`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// ADD AI PROJECTS

export const addAiProjects = createAsyncThunk(
  "addAiProjects",
  async ({data}:{data:{name:string,description:string}}, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/ai-projects`,data);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete AI PROJECTS

export const deleteAiProjects = createAsyncThunk(
  "deleteAiProjects",
  async (projectId:{projectId:any}, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(`${dynamic_url}/ai-projects/${projectId}`);
      res.data.id = projectId
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
// Edit AI PROJECTS

export const editAiProjects = createAsyncThunk(
  "editAiProjects",
  async ({data,projectId}:{data:any,projectId:any}, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.put(`${dynamic_url}/ai-projects/${projectId}`,data);
      res.data.id = projectId
     REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


// changeAiModel

export const changeAiModel = createAsyncThunk(
  "changeAiModel",
  async ({data}:{data:{model:string,convoId:string}}, { dispatch, rejectWithValue, getState }) => {
    try {
      // console.log(data);
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/changeModel/${data.convoId}`,{model:data.model});
      res.data.model = data.model
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
