/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import ReactHtmlParser from "react-html-parser";

import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";
import logo from "../../../../../assets/images/half-logo.png";

const CurrentResponse = (props: any) => {
  const { currentResponse } = props;

  return (
    <div className="chatbot__answer">
      <div className="chatbot__answer__wrapper">
        <div className="image__width__handle">
          <div className="image__avatar">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="chatbot__answer__handle">
          <div className="chatbot__answer">
            {/* <p></p> */}
            {/* {ReactHtmlParser(currentResponse)} */}
              <Markdown rehypePlugins={[rehypeRaw,rehypeHighlight]}>
                                                            {`${currentResponse}`}
                                                          </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentResponse;
