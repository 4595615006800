/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from "react";
import { Link, Minus, Plus, Trash2 } from "react-feather";

import "./faqcontent.scss";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import VideoCard from "./VideoCard";
import Feedback from "./Feedback";
import FeedBackLists from "./FeedBackLists";
import { setVideoIdForDelete } from "../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import BunnyIframe from "../../../../NewAcademicUser/InterviewPreparation/VideoRecord/BunnyIframe";
import VideoProcessing from "./VideoProcessing";
import LinkGenerateModal from "../../../AdminInterview/RecentlyAnswered/RecentAnserFaq/RecentAnswerFaqContent/RecentAnswerQuestion/LinkGenerateModal/LinkGenerateModal";
import AudioPlayer from "../../../../../shared/AudioPlayer/AudioPlayer";

const QuestionAnswerAccording = (props: any) => {
  const {
    id,
    data,
    index,
    setDeleteShowModal,
    setVideoDeleteShowModal,
    authState,
    InterviewState,
  } = props;
  const [ratingCount, SetRatingCount] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [activeAnswer, setActiveAnswers] = useState<any>({});
  const [showActionButton, setShowActionButton] = useState(false);
  const [actionData, setActionData] = useState<any>(null);
  // const [editFeedback, setEditFeedback] = useState(false);

  const [loadingId, setLoadingId] = useState(0);
  const [showFeedbackAgain, setShowFeedbackAgain] = useState(false);
  const [showFeedbackForEdit, setShowFeedbackForEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [openSharableModal,setopenshareableModal] = useState(false);
  const videoRef: any = useRef();
  const videoSourceRef: any = useRef();
  const [videoId, setVideoId] = useState("");
  const [shareableLink, setShareableLink] = useState("");

  const dispatch = useAppDispatch();

  // deleteFeedbackFromAnswers;

  const hideShowToggle = (data: any) => {
    setIsActive(!isActive);

    if (data?.answers?.length === 1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (data?.answers?.length > 0) {
      setActiveAnswers(data.answers[0]);
      SetRatingCount(data.answers[0].rating);
    }
    setLoadingId(data.id);
  };

  const setActiveAnswer = (answer: any, index: number) => {
    if (index + 1 === data?.answers?.length) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    setActiveAnswers(answer);
    SetRatingCount(answer.rating);
    setShowFeedbackAgain(false);
    setShowFeedbackForEdit(false);
  };

  const openDeleteModal = (answer: any) => {
    setVideoDeleteShowModal(true);
    dispatch(setVideoIdForDelete(answer.id));
  };

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("three__dot") &&
      !e.target.classList.contains("select__section") &&
      !e.target.classList.contains("select__action") &&
      !e.target.classList.contains("action__icon") &&
      !e.target.classList.contains("action__divider")
    ) {
      setShowActionButton(false);
    }
  });

  useEffect(() => {
    if (
      InterviewState.notificationData &&
      id === InterviewState.notificationData.question_id
    ) {
      console.log("interview-", InterviewState.notificationData);
      setIsActive(true);

      if (data?.answers?.length === 1) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      if (data?.answers?.length > 0) {
        data.answers.forEach((element: any, index: number) => {
          if (
            element.id ===
            InterviewState.notificationData.interview_answer_video_id
          ) {
            setActiveAnswers(data.answers[index]);
            SetRatingCount(data.answers[index].rating);
          }
        });
      }
      setLoadingId(data.id);
    }
  }, [InterviewState.notificationData, id]);

  const closeModal = ()=>{
    setopenshareableModal(false);
  }

  const openShareModal = (id: any, shareableLink: any) => {
    setopenshareableModal(true);
    setVideoId(id);
    setShareableLink(shareableLink);
  }
  console.log("Active Answer",activeAnswer)
  console.log(activeAnswer?.video_link ?.endsWith(".mp3"));
  const isAudioFile = activeAnswer?.video_link?.endsWith(".mp3");
  return (
    <div className="question_container">
      <div
        className="question_wrapper"
        aria-hidden
        onClick={() => hideShowToggle(data)}
        id={id}
      >
        <div className="question">
          <p>{data.question}</p>
        </div>
        <div className="attempt_on">
          <p>Attempted on: {data.attempted_on}</p>
          {isActive ? <Minus /> : <Plus />}
        </div>
      </div>

      {isActive && data?.answers?.length > 0 && (
        <div className="answer_content">
          <div className="left_content">
            <h3>Recorded responses</h3>
            <div className="video_title">
              {data.answers.map((answer: any, index: number) => {
                return (
                  <div className="video__counter" key={answer.id}>
                    {" "}
                    <span
                      style={{
                        color:
                          answer.video_link === activeAnswer.video_link
                            ? "#6F6FF1"
                            : "#667085",
                      }}
                      aria-hidden
                      onClick={() => setActiveAnswer(answer, index)}
                    >
                      {index + 1}
                      <sup>
                        {" "}
                        {index + 1 === 1 && "st"} {index + 1 === 2 && "nd"}
                        {index + 1 === 3 && "rd"}
                        {index + 1 > 3 && "th"}
                      </sup>{" "}
                      Response
                    </span>
                    <div className="generate__sharable__link"  onClick={()=>openShareModal(answer?.id, answer.shareable_link)}>
                      <Link/>
                    </div>
                    {authState.tac_user?.role === "sys_adm" && (
                      <Trash2
                        className="delete__icon"
                        onClick={() => openDeleteModal(answer)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {activeAnswer && (
             isAudioFile ? (
              <div className="admin__answer__audio__player">
              <AudioPlayer duration={activeAnswer?.duration} url={ activeAnswer?.video_link} />
            </div>
            ) :(
            <VideoCard
              videoRef={videoRef}
              videoSourceRef={videoSourceRef}
              activeAnswer={activeAnswer}
            />
            )

            // <BunnyIframe videoId={activeAnswer.video_link} />
          )}
          {/* {activeAnswer?.status === 0 && <VideoProcessing />} */}

          {(activeAnswer.feedbacks.length === 0 || showFeedbackAgain) && (
            <Feedback
              activeAnswer={activeAnswer}
              setLoading={setLoading}
              loadingId={loadingId}
              ratingCount={ratingCount}
              SetRatingCount={SetRatingCount}
              id={data.id}
              loading={loading}
              actionData={actionData}
              showFeedbackAgain={showFeedbackAgain}
              showFeedbackForEdit={showFeedbackForEdit}
              setShowFeedbackAgain={setShowFeedbackAgain}
            />
          )}
          {activeAnswer.feedbacks.length > 0 && !showFeedbackAgain && (
            <FeedBackLists
              activeAnswer={activeAnswer}
              setShowFeedbackAgain={setShowFeedbackAgain}
              showActionButton={showActionButton}
              setShowActionButton={setShowActionButton}
              actionData={actionData}
              setActionData={setActionData}
              setDeleteShowModal={setDeleteShowModal}
              setShowFeedbackForEdit={setShowFeedbackForEdit}
              authState={authState}
            />
          )}
        </div>
      )}

      {isActive && (!data?.answers || data?.answers?.length === 0) && (
        <div className="not_given">Answer not given.</div>
      )}
      <LinkGenerateModal showModal={openSharableModal} setShowModal={setopenshareableModal}
        closeModal={closeModal}
        videoId={videoId}
        questionId={id}
        shareableLink={shareableLink}
      />
    </div>
  );
};

export default QuestionAnswerAccording;
