import React, { useEffect, useState } from "react";
import SelectDropDown from "../../../../../shared/SelectDropDown/SelectDropDown";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../../store/store";
import { Domain } from "../../../../../helpers/utils/DomainSwitcher/domains";
import "./changeAiModel.scss"
import { changeAiModel } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";

const ChangeAiModelDropdown = () => {
  const data = [
    {
      id: 1,
      name: " DeepSeek",
      value: "deepseek",
    },
    {
      id: 2,
      name: "OpenAI",
      value: "openai",
    },
  ];
  
    const dispatch = useAppDispatch();

    const aiState: any = useAppSelector((store: RootStore) => {
      return store.AiSlice;
    });
    // Find the AI model from the fetched conversation or default to deepseek
    const getDefaultModel = () => {
      return data.find((item) => item.value === aiState?.ai_model) || data[0];
    };
  
    const [selectedValue, setSelectedValue] = useState(getDefaultModel());

 
   
      const configState: any = useAppSelector((store: RootStore) => {
        return store.ThemeSlice;
      });
      const isAA= configState.configs.membership_enabled === Domain.TAA
      const isTAC= configState.configs.membership_enabled === Domain.TAC
      // console.log(aiState?.active_conversation_id,aiState?.convo_unique_id);
      // console.log(aiState?.active_conv_unique_id)

        const handleOption = (d: any) => {
          console.log(d);
          setSelectedValue(d);
          const modelData = {
            model:d?.value,
            convoId:aiState?.convo_unique_id
          }
          dispatch(changeAiModel({data:modelData}))
        };
   // Update selectedValue when aiState.ai_model changes
   useEffect(() => {
    setSelectedValue(getDefaultModel());
  }, [aiState?.ai_model]);
    
  return (
    <div className="cam__wrapper">
      <div className="cam__container">
        <SelectDropDown
          data={data}
          selectedValue={selectedValue.name}
          handleOption={handleOption}
          isDropdownBg
          paddingHandle
          isAA={isAA}
          isTAC={isTAC}
          isOptionInTop
        />
      </div>
    </div>
  );
};

export default ChangeAiModelDropdown;
