export const ROUTES = {
  LOGIN: "/",
  LOGIN2: "/login",
  SINGUP: "/sign-up",
  DEFAULT_PASSWORD_CHANGE: "/default-password-change",
  FORGET_PASSWORD: "/forget-password",
  RESET_PASSWORD: "/password/reset/:token",
  VERIFY_YOUR_EMAIL: "/verify-your-email",
  TENANT_LOADING: "/loading",
  MEMBER_SIGNUP: "/member-signup/:token",
  OUR_TEAMS: "/our-teams",
  CONTACT: "/contact",
  MENTOR_INTERVIEW: "/mentor/interview",

  DASHBOARD: "/dashboard",
  USER: "/user",
  TEAMS: "/teams",
  TENANT_TEAMS: "/tenant-teams",
  PAYMENT_PLANS: "/payment-plans",
  CERTIFICATE_FOR_TEAM:"/certificate-for-team",
  FAQS: "/faqs",
  PRODUCT_PAGE_FAQ: "/product-page-faqs",
  BLOG: "/blog",
  SEO: "/seo",
  ADD_LEARNING_PATH: "/add-learning-path",
  TESTIMONIALS: "/testimonials",
  TESTIMONIAL: "/testimonial",
  MANAGE_COURSE: "/manage-courses",
  LIST_LEARNING_COURSE: "/list-learning-course",
  LEARNING_PATH_COURSE: "/list-learning-courses/:path_id",
  ONBOARDING: "/onboarding",
  TUTIONS: "/tuitions",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
  LEARNING_RESOURCE: "/learning-resource",
  ABOUT_US: "/about-us",
  SITE_MANAGER: "/site-manager",
  ARCHIVE: "/archive",
  SPRINT: "/sprint",
  CREATE_SPRINT: "/create-sprint/:id",
  WEEKLY_LEARNING: "/weekly-learning",
  PRODUCT_PAGE_WEEKLY_LEARNING: "/product-page-weekly-learning",
  PRODUCT_PAGES_DATE_TIME: "/product-pages-date-time",
  APPLY_NOW: "apply-now",
  PRODUCT_PAGE_TESTIMONIAL: "/product-page-testimonial",
  OUR_CLASSES: "/our-classes",
  VIEW_DETAILS: "apply-now-details/:id",
  SUBSCRIBERS: "/",
  REFERENCE: "/reference",
  QUICK_LINKS: "/quicklinks",
  INTERVIEW: "/interview",
  MEET_WITH_US: "/meet-with-us",

  WEBMINAR: "/webinar",
  PODCAST: "/audio-podcast",
  PROFILE: "/profile",

  PROGRESS: "/progress",
  PROGRESS_COURSE: "/progress-course/:courseId",
  LEARNING_PATH: "/learning-path",
  LEARNING_PATH_SETPPER: "/learning-path-stepper/:pathId/:bundle",
  LIST_COURSES: "/list-course",
  VIEW_COURSES: "/view-course/:courseId",
  USER_RESOURCES: "/user-resources",
  INTERVIEW_AND_WEBINARS: "/interviews-and-webinars",
  MEET_WITH_TEAM: "/meet-with-team",

  MY_PROFILE: "/accounts",
  TENANT_OWNER_PROFILE: "/owner-profile",

  MY_CERTIFICATE: "/accounts/certificates",

  ACADEMIC_USER_DASHBOARD: "/academic-user-dashboard",
  PRODUCT_MANAGER_DASHBOARD: "/product-manager-user-dashboard",
  INTERVIEW_PREPRATION_INSTRUCTION: "/interview-preparation-instruction",
  INTERVIEW_PREPRATION: "/interview-preparation",
  INTERVIEW_PREPRATION_DONE: "/interview-prepration-done",
  AI_ASSIST: "/ai-assist",
  AI_CONVERSATION: "/ai-conversation",
  AI_CONVERSATION_WITH_HASHID: "/ai-conversation/:hashId",
  AI_CONVERSATION_WITH_HASHID_WIth_PROJECTID: "/ai-conversation/:hashId/:projectId",
  AI_CONVERSATION_WITH_PROJECTID: "/ai-conversation-project/:projectId",

  TAC_AI_ASSIST: "/ai-assist",
  TAC_AI_CONVERSATION: "/ai-conversation",
  TAC_AI_CONVERSATION_WITH_HASHID: "/ai-conversation/:hashId",

  USER_LEARNING_RESOURCES: "/user-learning-resources",
  USER_APPLICATION_TRACKING: "/user-application-tracking",
  USER_ALL_COURSES: "/user-allcourses",
  ARCHIVES: "/archives",
  COURSE_PROGRESS: "/course-progress/:id",
  USER_REFERENCE: "/user-reference",

  BLOGS: "/blogs",
  VIEW_SINGLE_BLOG: "/blogs/:slug",
  MY_ACCOUNT: "/myaccount",
  MY_PROFIL: "/my-profile",

  ORGANIZATION_DASHBOARD: "/organization",
  GROUPS: "/groups",
  VIEW_GROUPS: "/view-groups/:id",
  CREATE_NEW_GROUPS: "/create-new-groups",
  EDIT_GROUPS: "/edit-groups/:id",
  MEMBERS: "/members",
  INVITE_NEW_MEMBERS: "/invite-new-members",
  MEMBERS_DETAILS: "/members-details/:id",
  ADMIN_LEARNING_PATH: "/admin-learningpath",
  ADMIN_COURSE_DETAIL: "/admin-courses-detail/:id",
  ADMIN_LEARNING_PATH_COURSE: "/admin-learningpath-course/:id",
  ADMIN_COURSES: "/admin-courses",
  MEMBERS_ADD_ASSIGNMENT: "/members-add-assignment/:id",
  UPDATE_ASSIGNMENT: "/update-assignments/:userId/:pathId",
  MANAGE_ASSIGNMENT: "/manage-assignments",
  PODCASTS: "/audio-podcasts",
  WEBINARS: "/interviews-webinars",

  ORGANIZATION_USER_DASHBOARD: "/organization-user-dashboard",
  ORGANIZATION_USER_LEARNING_PATH: "/organization-user-learningpath",
  ORGANIZATION_USER_COURSES: "/organization-user-courses",
  ORGANIZATION_USER_COURSE_DETAIL: "/organization-user-courses-detail/:id",
  ORGANIZATION_USER_LEARNING_PATH_COURSE:
    "/organization-user-learningpath-detail/:id",
  PRODUCT_MANAGER_COURSES: "/product-manager-student-courses",
  PRODUCT_MANAGER_PROFILE: "/product-manager-profile",
  TRIAL_USER_COURSE: "/free-trial-course",
  LIST_ALL_COURSES:"/list-all-courses",
  ALL_PREMIUM_COURSES:"/all-premium-courses",
  SUBSCRIBER:"/subscriber",
  UPCOMING_SESSIONS_USERS:"/upcoming-session-users",
  TRAINING_GROUP_USERS:"/training-group-users",
  VIDEO_TOUR:"/video-tour",
  OUR_EXPERTS:"/our-experts",
  COHORTS:"/cohorts",
  BANNERS:"/banners",
   COURSES_PRICE:"/courses-price"
};

//
