/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-template */
import { ArrowRight, ArrowUp } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";
import {
  adduserQuestionToMessageStack,
  askNewQuestionToAi,
  setAttachments,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";

const InputMessage = () => {
  const dispatch = useAppDispatch();
  const questionRef: any = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [input, setInput] = useState<string>("");

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  // console.log(aiState?.attachments );
  const buttonDisable = aiState.loading.question ||
  aiState.loading.current_answer ||
  input.trim().length === 0
  const sbumitQuestion = () => {
    
    if (
      aiState.loading.question ||
      aiState.loading.current_answer ||
      input.trim().length === 0
    ) {
      return;
    }
    dispatch(setShowProjectInitialScreen(false));
    dispatch(adduserQuestionToMessageStack({message:input,attachments:[]}));
    dispatch(askNewQuestionToAi(input));
    if (!location.pathname.includes("/ai-conversation")) {
      navigate("/ai-conversation");
    }
    setInput("");
  };

  const resizeTextArea = () => {
    questionRef.current.style.height = "auto";
    questionRef.current.style.height = `${questionRef.current.scrollHeight}px`;
  };
  useEffect(resizeTextArea, [input]);
  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  const handleKeycode = (event: any) => {
    if (event.code === "Enter" || event.code === "enter") {
      event.preventDefault();
      sbumitQuestion();
    }
  };
  useEffect(()=>{
    dispatch(setAttachments([]))
  },[])
  return (
    <div className="message__input__section">
      {/* <input
        ref={questionRef}
        type="text"
        placeholder="Ask Anything"
        onKeyDown={(e) => handleKeyDown(e)}
      /> */}

      <textarea
        ref={questionRef}
        className={`chat__textarea ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "chat__textarea__input "}`}
        value={input}
        rows={1}
        placeholder="Ask anything..."
        onChange={handleInputChange}
        onKeyDown={handleKeycode}
      >
        {" "}
      </textarea>

      <button type="button" disabled={buttonDisable}  className={`right_arrow ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "right__arrow__icon "}`}>
      <ArrowUp
        role="button"
        aria-hidden
       
        onClick={() => sbumitQuestion()}
      />
      </button>
    </div>
  );
};

export default InputMessage;
