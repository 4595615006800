/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  ChevronDown,
  ChevronUp,
  Edit2,
  Folder,
  MoreHorizontal,
  Trash2,
} from "react-feather";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import { setShowDeleteProjectModal, setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";
import { getSingleAiProject, setActiveConversationUniqueId, setSelectedProjectId } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import { fetchConversation } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const ChatProjectItem = (props: any) => {
  const { onClick, showThreedot, data, setProjectId, openProjectId,
    setOpenProjectId, isInAiasistPage, fromChatBot, localProjectIdForChatBot, 
    setLocalProjectIdForChatBot } = props;

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const projectId = new URLSearchParams(location?.search).get("projectId");
  const conversationId = params?.hashId;

  const dispatch = useAppDispatch();
  const aiState: any = useAppSelector((store: RootStore) => store.AiSlice);
    const configState: any = useAppSelector((store: RootStore) => {
      return store.ThemeSlice;
    });
    const isAA= configState.configs.membership_enabled === Domain.TAA
    const isTAC= configState.configs.membership_enabled === Domain.TAC

  const isOpen = fromChatBot 
    ? localProjectIdForChatBot === data?.id 
    : openProjectId === data?.id;

  const handleProjectDeleteClick = (e: any, id: any) => {
    e.stopPropagation();
    setProjectId(id);
    dispatch(setShowDeleteProjectModal(true));
  };

  const handleProjectEditClick = (e: any, d: any) => {
    e.stopPropagation();
    dispatch(getSingleAiProject(d));
  };

  const handleNavigateConversastionInsideProject = (e: any, conv: any) => {
    e.stopPropagation();
    dispatch(setShowProjectInitialScreen(false));
    
    if (!fromChatBot) {
      navigate(`/ai-conversation/${conv?.uniqueId}?projectId=${projectId}`);
      dispatch(fetchConversation({ id: conv?.uniqueId, projectId: Number(projectId) }));
    } else {
      dispatch(fetchConversation({ id: conv?.uniqueId, projectId: conv?.project_id }));
       dispatch(setActiveConversationUniqueId(conv?.uniqueId));
    }
  };

  const toggleProjectOpen = (e: any, pId: any) => {
    e.stopPropagation();
    
    if (!fromChatBot) {
      setOpenProjectId((prev:any) => prev === pId ? null : pId);
      navigate(`/ai-conversation?projectId=${pId}`);
    } else {
      setLocalProjectIdForChatBot((prev:any) => (prev === pId ? null : pId));
    }
  };
  return (
    <div>
      <button type="button"
        className={`chat__project__item ${
          fromChatBot
            ? aiState?.selectedProjectID === data?.id && "active"
            : data?.id === Number(projectId) && "active"
        } ${isInAiasistPage && "isAiassitsPageProjectItem"} ${isTAC && isInAiasistPage && "isTACisAiassitsPageProjectItem"} ${
              (aiState.loading.question || aiState.loading.current_answer) &&
              "disabled"
            }`}
          disabled={aiState.loading.question || aiState.loading.current_answer}
        onClick={(e: any) => {
          if (fromChatBot) {
            dispatch(setSelectedProjectId(data?.id));
          }
          onClick(e, data?.id);
        }}
      >
        <div className="left">
          <Folder />
          <p> {!isInAiasistPage && data?.name?.length > 20 ? `${data?.name?.slice(0, 18)}...` : data?.name}</p>
         
        </div>
        <div className="threedot__toggle">
          {!showThreedot && (
            <>
              <div className="threedot">
                <span><MoreHorizontal className="morehorizontal" /></span>
                <div className={`actions__wrapper ${isAA && "isAA"} ${isTAC && "isTAC"}`}>
                  <button
                    type="button"
                    className="edit__project__btn"
                    onClick={(e) => handleProjectEditClick(e, data)}
                  >
                    <Edit2 /> Edit
                  </button>
                  <button
                    type="button"
                    className="delete__project__btn"
                    onClick={(e) => handleProjectDeleteClick(e, data?.id)}
                  >
                    <Trash2 /> Delete
                  </button>
                </div>
              </div>

              {data?.conversations?.length > 0 && (
                <div
                  className="conversation-toggle"
                  // onClick={(e) => toggleProjectOpen(e, data?.id)}
                >
                  {isOpen ? <ChevronUp /> : <ChevronDown />}
                </div>
              )}
            </>
          )}
        </div>
      </button>

      {isOpen && (
        <div className="conversation-list">
          {data?.conversations?.map((conv: any) => (
            <div
              key={conv.id}
              className={`conversation-item ${
                fromChatBot
                  ? conv?.uniqueId === aiState?.active_conv_unique_id && "active"
                  : conv?.uniqueId === conversationId && "active"
              }`}
              onClick={(e) => onClick(e, data?.id, conv.uniqueId)}
            >
              <p onClick={(e) => handleNavigateConversastionInsideProject(e, conv)}>
              {
                !fromChatBot 
                  ? (conv?.question.length > 25 
                      ? `${conv.question.slice(0, 25)}...` 
                      : conv?.question
                    )
                  : (conv?.question.length > 35 
                      ? `${conv.question.slice(0, 35)}...` 
                      : conv?.question
                    )
              }
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ChatProjectItem;