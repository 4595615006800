/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import "react-tooltip/dist/react-tooltip.css";

import {
  clearConversation,
  fetchConversation,
  getRecentSearch,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { NewChatButton } from "../NewChatButton/NewChatButton";
import Question from "./Question";
import "./index.scss";
import { openNewChat } from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import ChatProjects from "../ChatProjects/ChaProjects";
import { setShowProjectInitialScreen } from "../../../../store/features/UI/UISlice";

const ChatSidebar = (props: any) => {
  const { setHashId } = props;
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const handleConversation = (id: string) => {
    if (aiState.loading.question) {
      return;
    }
    navigate(`/ai-conversation/${id}`);
    setHashId(id);
    dispatch(fetchConversation({ id: id }));
    dispatch(setShowProjectInitialScreen(false));
  };

  useEffect(() => {
    dispatch(getRecentSearch());
  }, []);

  const newChat = () => {
    dispatch(openNewChat());
    navigate("/ai-conversation");
    dispatch(setShowProjectInitialScreen(false));
    // setOpenProjectId(null);

  };

  const clearAllConversation = () => {
    dispatch(clearConversation());
    navigate("/ai-conversation");
    // setOpenProjectId(null);
  };
  return (
    <div className="chat__sidebar__section">
      <ChatProjects mr0={false} />

      <h3 className="rc__title">Recent Chat</h3>
      <div className="questions__section chat__custom__scrollbar">
        {aiState.req_success.all_conversation &&
          aiState.all_conversation.map((conversation: any) => {
            return (
              <a
                key={conversation.uniqueId}
                className="my-anchor-element"
                data-tooltip-id="tool-tip"
                data-tooltip-content={
                  conversation.question.length > 72 ? conversation.question : ""
                }
              >
                <Question
                  id={conversation.uniqueId}
                  question={conversation.question}
                  handleConversation={handleConversation}
                  active={conversation.uniqueId === params.hashId}
                />
              </a>
            );
          })} 
      </div>
      <div className="mt-1" />
      <div className="button__padding">
        {" "}
        <NewChatButton onClick={newChat} />
      </div>
      {aiState.all_conversation.length > 0 && (
        <div className="clear__div">
          {" "}
          <button
            type="button"
            className={`clear_conversation__button ${
              (aiState.loading.question || aiState.loading.current_answer) &&
              "disabled"
            }`}
            onClick={clearAllConversation}
            disabled={
              aiState.loading.question || aiState.loading.current_answer
            }
          >
            Clear conversations
          </button>
        </div>
      )}

      <Tooltip
        id="tool-tip"
        place="bottom"
        classNameArrow="custom-react-tooltip-arrow"
        className="custom-tool-tip"
        offset={-10}
        // events={["click"]}
      />
    </div>
  );
};

export default ChatSidebar;
