/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./chatbotquestionsidebar.scss";
import { MessageSquare, Plus } from "react-feather";
import { useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  clearConversation,
  fetchConversation,
  getRecentSearch,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { openNewChat, setActiveConversationUniqueId, setSelectedProjectId } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import ChatProjects from "../../../AiAssist/ChatProjects/ChaProjects";
import { setShowProjectInitialScreen } from "../../../../../store/features/UI/UISlice";

const ChatboxQuestionSidebarBox = (props: any) => {
  const {localProjectIdForChatBot,setLocalProjectIdForChatBot} = props;
  
  
  const dispatch = useAppDispatch();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  // Navigate to conversastion screen 
  const handleConversation = (id: string) => {
    if (aiState.loading.question) {
      return;
    }

    dispatch(fetchConversation({ id: id }));
    dispatch(setShowProjectInitialScreen(false));
    setLocalProjectIdForChatBot(null);
    dispatch(setSelectedProjectId(null));
  };
// clearChattingScreen;
  const clearAllConversation = () => {
    dispatch(clearConversation());
  };
  
// New Chat 
  const newChat = () => {
    dispatch(openNewChat());
    dispatch(setShowProjectInitialScreen(false));
    dispatch(setSelectedProjectId(null));
    setLocalProjectIdForChatBot(null);
    dispatch(setActiveConversationUniqueId(null));
  };
  return (
    <div className="question__sidebar">
      <div className="question__sidebar__wrapper">
        <ChatProjects fromChatBot={true} localProjectIdForChatBot={localProjectIdForChatBot} setLocalProjectIdForChatBot={setLocalProjectIdForChatBot}/>
        <div className="question__header">Recent Conversations</div>
        <div className="question__sidebar__container">
          <div className="question__wrapper">
            {aiState.req_success.all_conversation &&
              aiState.all_conversation.map((conversation: any) => {
                return (
                  <a
                    key={conversation.uniqueId}
                    className="my-anchor-element"
                    data-tooltip-id="tool-tip"
                    data-tooltip-content={
                      conversation.question.length > 72
                        ? conversation.question
                        : ""
                    }
                  >
                    <div className="question__div">
                      <div
                        className="question__item"
                        role="button"
                        aria-hidden
                        onClick={() =>
                          handleConversation(conversation.uniqueId)
                        }
                      >
                        <div className="message__icon__handle">
                          <MessageSquare className="square__messagebox" />
                        </div>
                        <span>{conversation.question} </span>
                      </div>
                      <div className="question__underline" />
                    </div>
                  </a>
                );
              })}
          </div>

          {aiState.all_conversation.length > 0 && (
            <div className="clear__all">
              <button
                type="button"
                onClick={clearAllConversation}
                disabled={
                  aiState.loading.question || aiState.loading.current_answer
                }
              >
                Clear conversations
              </button>
            </div>
          )}
          <div className="button__wrapper">
            <button
              type="button"
              disabled={
                aiState.loading.current_answer || aiState.loading.question
              }
              onClick={() => newChat()}
            >
              <Plus />
              New Chat
            </button>
          </div>
        </div>
      </div>

      <Tooltip
        id="tool-tip"
        place="bottom"
        classNameArrow="custom-react-tooltip-arrow"
        className="custom-tool-tip-ai-popup"
        offset={-10}
        // events={["click"]}
      />
    </div>
  );
};

export default ChatboxQuestionSidebarBox;
