/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import ReactHtmlParser from "react-html-parser";
import Markdown from "react-markdown";
import rehypeHighlight from "rehype-highlight";
import rehypeRaw from "rehype-raw";

import "./index.scss";

const CurrentResponse = (props: any) => {
  const { message, themeState } = props;
  // console.log("current Message",message);
  return (
    <div className="center__messages">
      <div className="message__section  ai">
        <div className="center__item">
          <div className="image__avatar">
            <img src={themeState.configs.sidebar_closed_logo} alt="" />
          </div>
          <div className="message__and__buttons__div">
            <div
              className="message"
            >
              {" "}
              {/* {ReactHtmlParser(message)} */}
              <Markdown rehypePlugins={[rehypeRaw,rehypeHighlight]}>
              {`${message}`}
          </Markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentResponse;
