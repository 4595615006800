import React, { useEffect, useRef, useState } from "react";
import "./chatbotsection.scss";
import ChatboxInputMessage from "./ChatbotInputMessage/ChatboxInputMessage";
import ChatboxQuestionSidebarBox from "./ChatbotQuestionBox/ChatboxQuestionSidebarBox";
import ChatbotHeader from "./ChatbotHeader/ChatbotHeader";
import MobileChatQuestionSidebar from "./ChatbotQuestionBox/MobileChatQuestionSidebar";
import ChatbotConversation from "./ChatbotConversation/ChatbotConversation";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  clearConversation,
  fetchConversation,
  getRecentSearch,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import CreateChatProjectModal from "../../AiAssist/ChatProjects/CreateChatProjectModal/CreateChatProjectModal";
import ChatProjectsInitialScreen from "../../AiAssist/ChatProjects/ChatProjectsInitialScreen/ChatProjectsInitialScreen";
import ProjectChatInitialScreen from "../../AiAssist/ChatingArea/ProjectChatInitialScreen/ProjectChatInitialScreen";
import NewInputMessage from "../../AiAssist/InputMessage/NewInputMessage";

const ChatBotSection = (props: any) => {
  const { setShowChatBox, chatBotIconRef } = props;  

 

  const [showChatQuestionSlider, setShowQuestionChatSlider] = useState(false);
    const [localProjectIdForChatBot,setLocalProjectIdForChatBot] = useState<number | null>(null)

    const chatbotSection: any = useRef(null);
    const dispatch = useAppDispatch();

   const aiState: any = useAppSelector((store: RootStore) => {
      return store.AiSlice;
    });

   const uiSlice: any = useAppSelector((store) => store.UISlice);

  const handleClickOutSide = (event: any) => {
    if (
      chatBotIconRef.current &&
      !chatBotIconRef.current.contains(event.target)
    ) {
      if (
        chatbotSection.current &&
        !chatbotSection.current.contains(event.target)
      ) {
        setShowChatBox(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  useEffect(() => {
    dispatch(getRecentSearch());
  }, []);

    const singleProjectData = aiState?.all_ai_projects?.filter(
      (pro: any) => pro?.id === Number(aiState?.selectedProjectID)
    );
// console.log(uiSlice?.showProjectInittialScreen);
  return (
    <div className="chatbot__section__wrapper">
      <div className="chatbot__section__container">
        <div ref={chatbotSection} className="chatbot__section__div">
          <div className="chatbot__section__div__container">
            <ChatboxQuestionSidebarBox localProjectIdForChatBot={localProjectIdForChatBot} setLocalProjectIdForChatBot={setLocalProjectIdForChatBot}/>

            <MobileChatQuestionSidebar
              showChatQuestionSlider={showChatQuestionSlider}
              setShowQuestionChatSlider={setShowQuestionChatSlider}
              localProjectIdForChatBot={localProjectIdForChatBot} setLocalProjectIdForChatBot={setLocalProjectIdForChatBot}
            />

            <div className="chatbot__chatting__area">
              <ChatbotHeader
                setShowChatBox={setShowChatBox}
                showChatQuestionSlider={showChatQuestionSlider}
                setShowQuestionChatSlider={setShowQuestionChatSlider}
              />
              {
                  uiSlice?.showProjectInittialScreen ? (
                    <ProjectChatInitialScreen singleProjectData={singleProjectData[0]} fromChatBot={true}/>
                  ):(
                    <>
                        <ChatbotConversation singleProjectData={singleProjectData[0]}/>

                        <div className="chat__input__area__section">
                          {/* <ChatboxInputMessage /> */}
                          <NewInputMessage fromChatBot={true}/>
                        </div>
                    </>

                  )
              }

          
            </div>
          </div>
          <CreateChatProjectModal/>
        </div>
      </div>
     
    </div>
  );
};

export default ChatBotSection;
