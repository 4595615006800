import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../../helpers/utils/formUtils";

const validationSchema = yup.object().shape({
  name: yup.string().required(REQUIRED_VALIDATION("Project Name")),
    description: yup.string().required(REQUIRED_VALIDATION("Project details")),
});

export const formOptions: any = {
  resolver: yupResolver(validationSchema),
  mode: "onTouched",
  reValidateMode: "onChange",
};
